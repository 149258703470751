<script lang="ts" context="module">
  import { orgRightsNrk, orgRightsOther, orgRightsUnknown } from "@nrk/origo";

  const rights = {
    Free: { css: "free", icon: orgRightsNrk, description: "Fri gjenbruk i NRK" },
    Restricted: {
      css: "restricted",
      icon: orgRightsOther,
      description: "Begrensninger for gjenbruk",
    },
    Unknown: { css: "unknown", icon: orgRightsUnknown, description: "Ukjente vilkår" },
  };
</script>

<script lang="ts">
  import { type RightsMarker } from "bildebanken-model";

  export let rightsMarker: RightsMarker;

  $: right = rights[rightsMarker];
</script>

{#if right}
  <span class={right.css} title={right.description}>
    {@html right.icon}
    <span class="org-sr">{right.description}</span>
  </span>
{/if}

<style>
  span :global(svg) {
    height: 1.3em;
  }
  .free {
    color: var(--nrk-color-warm-green-400);
  }
  .restricted {
    color: var(--nrk-color-red-400);
  }
  .unknown {
    color: var(--nrk-color-warm-orange-400);
  }
</style>
