import { type SearchParams } from "../params";
import { type NtbSearchHit } from "services/searchHit";
import { getContext, setContext } from "svelte";
import { searchNtb } from "services/ntb";
import type { SearchHitStore, SearchQuery, SearchQueryKey, SearchQueryResult } from "./index";
import { createSearchQuery } from "./index";

export type SearchNtbQueryResult = SearchQueryResult<NtbSearchHit>;
const searchHitsNtbKey = Symbol("search-hits-ntb-key");
const searchQueryContextKey = Symbol("search-query-ntb-key");

export function setNtbSearchHitsContext(hits$: SearchHitStore<NtbSearchHit>) {
  setContext(searchHitsNtbKey, hits$);
}

export function getNtbSearchHits(): SearchHitStore<NtbSearchHit> {
  return getContext(searchHitsNtbKey);
}

export function setNtbSearchQuery(query: SearchQuery<NtbSearchHit>) {
  setContext(searchQueryContextKey, query);
}

export function getNtbSearchQuery(): SearchQuery<NtbSearchHit> {
  return getContext(searchQueryContextKey);
}

function ntbSearchQueryKey(params: SearchParams): SearchQueryKey {
  return ["ntbImages", params];
}

export function createNtbSearchQuery(params: SearchParams): SearchQuery<NtbSearchHit> {
  return createSearchQuery(params, searchNtb, ntbSearchQueryKey);
}
