import { searchKaleido } from "services/kaleido";
import { type KaleidoSearchHit } from "services/searchHit";
import { getContext, setContext } from "svelte";
import { type SearchParams } from "../params";
import type { SearchHitStore, SearchQuery, SearchQueryKey, SearchQueryResult } from "./index";
import { createSearchQuery } from "./index";

export type SearchKaleidoQueryResult = SearchQueryResult<KaleidoSearchHit>;
const searchHitsKaleidoKey = Symbol("search-hits-kaleido-key");
const searchQueryContextKey = Symbol("search-query-kaleido-key");

export function setKaleidoSearchHitsContext(hits$: SearchHitStore<KaleidoSearchHit>) {
  setContext(searchHitsKaleidoKey, hits$);
}

export function getKaleidoSearchHits(): SearchHitStore<KaleidoSearchHit> {
  return getContext(searchHitsKaleidoKey);
}

export function setKaleidoSearchQuery(query: SearchQuery<KaleidoSearchHit>) {
  setContext(searchQueryContextKey, query);
}

export function getKaleidoSearchQuery(): SearchQuery<KaleidoSearchHit> {
  return getContext(searchQueryContextKey);
}

function kaleidoSearchQueryKey(params: SearchParams): SearchQueryKey {
  return ["kaleidoImages", params];
}

export function createKaleidoSearchQuery(params: SearchParams): SearchQuery<KaleidoSearchHit> {
  return createSearchQuery(params, searchKaleido, kaleidoSearchQueryKey);
}
