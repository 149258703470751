<script lang="ts">
  //@ts-ignore
  import BbLogo from "../../../public/img/bildebanken-logo.svg";
</script>

<div class="logo">
  <BbLogo />
</div>

<style>
  .logo {
    & path {
      fill: var(--color-logo-primary);
    }
    & .text {
      fill: var(--color-logo-secondary);
    }
  }
</style>
