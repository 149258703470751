<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import type { BackgroundImage } from "./featuredImages";

  export let images: BackgroundImage[] = [];
  export let imagesOffset = 0;

  const dispatch = createEventDispatcher();

  let scrolled = 0;
  $: currentIndex = Math.round(scrolled * (images.length - 1));

  const onScroll = (event: Event) => {
    const target = event.target as HTMLElement;
    scrolled = target.scrollTop / (target.scrollHeight - target.clientHeight);
  };

  let currentImage: BackgroundImage | undefined = undefined;
  $: currentImage = images[(currentIndex + imagesOffset) % images.length];

  $: {
    dispatch("change", { image: currentImage });
  }

  function preload(images: BackgroundImage[]) {
    images.forEach((image) => {
      const imgEl = new Image();
      imgEl.src = image.url;
    });
  }

  onMount(() => {
    // Trigger change event for initial image
    currentImage = currentImage;
  });

  $: {
    const from = (currentIndex + 0 + imagesOffset) % images.length;
    const to = (currentIndex + 3 + imagesOffset) % images.length;
    preload(images.slice(from, to));
  }

  /* Calculate blur amount from inbetween scroll position */
  $: distance = (scrolled * (images.length - 1) * 2) % 2;
  $: blurAmount = distance > 1 ? 2 - distance : distance;
</script>

<div class="wrapper">
  <div
    class="scrollable-background-layer"
    style="--background-image: url({currentImage?.url || ''}); 
           --background-position: {(currentImage?.position || 0.5) * 100}%; 
           --background-blur-amount: {blurAmount};"
    on:scroll={onScroll}
  >
    {#each Array(images.length) as _}
      <div class="scroll-page"></div>
    {/each}
  </div>
  <div class="content-layer">
    <slot name="overlay" />
  </div>
  <div class="content-layer flex">
    <slot name="overlay-flex" />
  </div>
</div>

<style>
  .wrapper {
    width: 100%;
    height: 100%;

    /* Reset stacking context */
    position: relative;

    /* Avoid blur leaking to adjecent layout */
    overflow: hidden;
  }

  .scrollable-background-layer {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-snap-stop: always;
    background: var(--background-image) center var(--background-position) / cover no-repeat;
    filter: blur(calc(var(--background-blur-amount) * 20px));
  }

  /* Geocko (Firefox) */
  .scrollable-background-layer {
    scrollbar-width: none;
  }
  /* Webkit (Safari), Blink (Chromium) */
  .scrollable-background-layer::-webkit-scrollbar {
    display: none;
  }

  .scroll-page {
    height: 100%;
    scroll-snap-align: center;
  }

  .content-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .content-layer.flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Pass scroll events to background layer */
  .content-layer {
    pointer-events: none;
  }

  /* Catch user events for content */
  .content-layer :global(*) {
    pointer-events: initial;
  }
</style>
