<script lang="ts">
  import PluginMain from "components/PluginMain.svelte";
  import { getCloseCallback } from "config";
  import { listenForMessages, stopListeningForMessages } from "services/pluginMessages";
  import { onMount } from "svelte";
  import Providers from "../Providers/Providers.svelte";
  import GlassPane from "./GlassPane.svelte";

  const onKeyDown = (event: KeyboardEvent) => {
    const targetEl = event.target as HTMLElement;
    if (event.key === "Escape") {
      if (targetEl.nodeName === "INPUT" && (targetEl as HTMLInputElement).type === "text") {
        targetEl.blur();
      } else {
        const cancelCallback = getCloseCallback();
        if (cancelCallback) {
          cancelCallback();
        }
      }
    }
  };

  onMount(() => {
    window.addEventListener("keydown", onKeyDown);
    listenForMessages();
    return () => {
      window.removeEventListener("keydown", onKeyDown);
      stopListeningForMessages();
    };
  });
</script>

<div class="org-root org-theme-dark" id="bildebanken-root">
  <Providers>
    <PluginMain />
    <GlassPane />
  </Providers>
</div>

<style>
  div.org-root {
    font:
      0.875rem/1.7 Roboto,
      sans-serif;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    isolation: isolate;
    overflow: hidden;
  }
</style>
