<script lang="ts">
  export let src: string;
  export let srcHighRes: string | undefined;
  export let setIsFullscreen: (value: boolean) => void;
  export let isFullscreen: boolean = false;

  let imgEl: HTMLImageElement;
  let zoom = false;

  function handleDialogEvent(this: HTMLElement, event: Event) {
    let target: HTMLElement = event.target as HTMLElement;
    if (this === target) {
      setIsFullscreen(!target.hidden);
    }
  }

  function zoomAtPointer(this: HTMLElement, event: MouseEvent) {
    if (zoom) {
      const x = (event.offsetX / this.offsetWidth) * 100;
      const y = (event.offsetY / this.offsetHeight) * 100;
      this.style.backgroundPosition = x + "% " + y + "%";
    }
  }

  function toggleZoom(this: HTMLElement, event: MouseEvent) {
    zoom = !zoom;
    imgEl.style.opacity = zoom ? "0" : "1";
    zoomAtPointer.call(this, event);
  }
</script>

<bb-dialog class="fullscreen-dialog" hidden={!isFullscreen} on:dialog.toggle={handleDialogEvent}>
  <div class="figure-wrapper">
    <!-- Not so important to have a keyboard accessible version of this-->
    <!-- svelte-ignore a11y-click-events-have-key-events a11y-mouse-events-have-key-events a11y-no-noninteractive-element-interactions -->
    <figure
      on:click={toggleZoom}
      class:zoom
      style={`background-image: ${zoom ? `url(${srcHighRes})` : "none"} `}
      on:mousemove={zoomAtPointer}
      on:mouseleave={() => {
        zoom = false;
        imgEl.style.opacity = "1";
      }}
    >
      <img {src} alt="Forhåndsvisning bilde" bind:this={imgEl} />
    </figure>
  </div>
</bb-dialog>

<style>
  .fullscreen-dialog {
    width: 95vw;
    height: 95vh;
    max-width: none;
    max-height: none;
  }

  .figure-wrapper {
    height: 100%;
    max-height: 95vh;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: var(--org-medium);
  }

  figure {
    background-position: 50% 50%;
    position: relative;
    overflow: hidden;
    cursor: zoom-in;
    background-repeat: no-repeat;
    max-height: 100%;
    width: fit-content;
    max-width: 100%;
    margin: 0;
  }

  figure.zoom {
    background-image: none;
  }

  .zoom img {
    cursor: zoom-out;
  }
</style>
