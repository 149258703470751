<script lang="ts">
  import { orgSortAsc, orgSortDesc } from "@nrk/origo";
  import { sortParams, updateSortParams } from "state/page";
  import type { SortParams } from "state/params";

  const sortImageByKey = "bildebanken-sort-by";
  const sortImageOrderKey = "bildebanken-sort-order";

  function setSortBy(value: SortParams["sortBy"]) {
    return function (this: HTMLInputElement) {
      if (!this.checked) return;
      localStorage.setItem(sortImageByKey, value);
      updateSortParams({ sortBy: value });
    };
  }

  function setSortOrder(value: SortParams["sortOrder"]) {
    return function (this: HTMLInputElement) {
      if (!this.checked) return;
      localStorage.setItem(sortImageOrderKey, value);
      updateSortParams({ sortOrder: value });
    };
  }

  const sortableFields: { field: SortParams["sortBy"]; label: string }[] = [
    {
      field: "mediaCreatedOn",
      label: "Fotografert",
    },
    {
      field: "createdOn",
      label: "Lastet opp",
    },
    // We leave this out until it is explicitly asked for by users
    // {
    //   field: "modifiedOn",
    //   label: "Sist endret",
    // },
  ];

  const sortOrders: { order: SortParams["sortOrder"]; label: string }[] = [
    {
      order: "desc",
      label: "Nyeste først",
    },
    {
      order: "asc",
      label: "Eldste først",
    },
  ];
</script>

<button
  class="org-button org-button--secondary"
  type="button"
  aria-label="Velg sortering av treff"
  title={"Dato " + sortableFields.find((field) => field.field === $sortParams.sortBy)?.label}
>
  {@html $sortParams.sortOrder === "desc" ? orgSortDesc : orgSortAsc}
</button>
<bb-dropdown hidden>
  <fieldset class="org-fieldset">
    <legend>Sorter etter</legend>
    {#each sortableFields as field (field.field)}
      <label>
        <input
          class="org-input"
          type="radio"
          name="sort-by"
          value={field.field}
          checked={$sortParams.sortBy === field.field}
          on:change={setSortBy(field.field)}
        />
        {field.label}
      </label>
    {/each}
  </fieldset>
  <fieldset class="org-fieldset">
    <legend>Rekkefølge</legend>
    {#each sortOrders as order (order.order)}
      <label>
        <input
          class="org-input"
          type="radio"
          value={order.order}
          name="sort-order"
          checked={$sortParams.sortOrder === order.order}
          on:change={setSortOrder(order.order)}
        />
        {order.label}
      </label>
    {/each}
  </fieldset>
</bb-dropdown>

<style>
  label {
    display: block;
    margin: var(--org-small);
  }
  bb-dropdown {
    padding: var(--org-small);
  }
</style>
