<script lang="ts">
  import { orgFilter } from "@nrk/origo";
  import FilterMenu from "components/Filter/FilterMenu.svelte";
  import { isEmbedded } from "config";
  import { useFeatureToggle } from "services/configCatService";
  import { onMount } from "svelte";
  import Productions from "./Folders/Productions.svelte";
  import { recentFolders } from "state/page";

  const persistedRecentFolders = localStorage.getItem("recentFolders");

  if (persistedRecentFolders) {
    $recentFolders = JSON.parse(persistedRecentFolders);
  }

  let isMobile = false;
  const embedded = isEmbedded();
  type SideMenuState = "filters" | undefined;
  $: sideMenuState = !embedded && !isMobile ? "filters" : undefined;

  $: sideMenuClosed = sideMenuState === undefined;
  onMount(async () => {
    //TODO: Make size variable
    isMobile = window.innerWidth < 1024;
  });
</script>

<div class="wrapper" class:sideMenuClosed class:embedded>
  <div class="header" class:sideMenuClosed>
    <button
      type="button"
      class="org-button container-expand"
      class:org-button--secondary={sideMenuState !== "filters"}
      title="Filtrer resultater"
      aria-controls="filter-container"
      aria-pressed={sideMenuState === "filters"}
      on:click={() => {
        if (sideMenuState === "filters") {
          sideMenuState = undefined;
        } else {
          sideMenuState = "filters";
        }
      }}
    >
      {@html orgFilter}
    </button>
    {#if sideMenuState === "filters"}
      <strong>Filtrer resultater</strong>
    {/if}
  </div>
  <div></div>

  {#if sideMenuState === "filters"}
    <FilterMenu></FilterMenu>
    {#await useFeatureToggle("showFolders") then featureEnabled}
      {#if featureEnabled}
        <div class="folders">
          <Productions />
        </div>
      {/if}
    {/await}
  {/if}
</div>

<style>
  .wrapper {
    position: sticky;
    padding: var(--org-xsmall);
    background: var(--color-surface-secondary);
    height: 87vh;
    width: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    transition: width 0.5s;
    top: 0px;
    z-index: 2;
  }

  .wrapper.embedded {
    height: 92vh;
  }

  .folders {
    display: flex;
    flex-direction: column;
    gap: var(--org-small);
    margin-top: 3rem;
  }

  .sideMenuClosed {
    width: 55px;
    transition: width 0.2s ease-in-out;
  }

  .header {
    white-space: nowrap;
  }

  .container-expand[aria-pressed="true"] {
    background: unset;
  }
</style>
