import { getAccessToken } from "../auth/auth";
import { getAuthenticationFlow, getRelease, isEmbedded } from "../config";
import { getPluginHost, pluginVersion } from "./pluginMessages";

const versionHeader = {
  "X-Client": "Bildebanken-UI-" + getRelease(),
};

export function getAppContext() {
  if (isEmbedded()) {
    const host = getPluginHost();
    return "plugin" + (host ? " " + host : "");
  }

  return "standalone " + window.location.hostname;
}

export async function apiFetch(...args: Parameters<typeof fetch>) {
  const accessToken = await getAccessToken(getAuthenticationFlow());

  if (!args[1]) {
    throw RangeError("apiFetch called without second argument");
  }

  const init = {
    ...args[1],
    headers: {
      ...args[1].headers,
      ...versionHeader,
      authorization: `Bearer ${accessToken}`,
      "X-Context": getAppContext(),
      "X-Bildebanken-Plugin-Version": pluginVersion,
    },
  };

  return fetch(args[0], init);
}
