import {
  ROLES,
  type Image,
  type Kaleido,
  type Metadata,
  type RightsMarker,
} from "bildebanken-model";
import { pick } from "lodash-es";
import type { NtbSearchHit } from "services/searchHit";
import type { KaleidoSearchHit } from "services/searchHit";

const METADATA_FIELDS = [
  "title",
  "description",
  "altText",
  "usageTerms",
  "imageDimensions",
] as const;

type MetadataFields = (typeof METADATA_FIELDS)[number];

export type SelectedImage = {
  id: string;
  metadata: Pick<Metadata, MetadataFields> & CreditMetadata;
  technicalMetadata: TechnicalMetadata;
  publications: Publication[];
  source: "Bildebanken" | "Kaleido" | string;
  preview?: PreviewInfo;
};

export type CreditMetadata = {
  credit: {
    imageType?: string; //ImageType
    creator?: string;
    rightsHolder?: string;
  };
  proposedCreditLine?: string;
};

export type Publication = {
  system: string;
  id: string;
};

export type PreviewInfo = {
  url: string;
  height?: number;
  width?: number;
};

export type TechnicalMetadata = {
  mediaType?: string;
  mediaCreatedOn?: string;
  width?: number;
  height?: number;
};

export type PublishedImage = Image & { metadata: { publicId: string } };
export function imageIsPublished(image: Image): image is PublishedImage {
  return !!image.metadata.publicId;
}

function cleanObject(obj) {
  if (typeof obj === "string") {
    return cleanString(obj);
  }
  if (Array.isArray(obj)) {
    return obj.map(cleanObject);
  }
  if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).reduce((acc, key) => {
      acc[key] = cleanObject(obj[key]);
      return acc;
    }, {});
  }
  return obj;
}

/**
 * Map image to SelectedImage, requires that the image has a publicId.
 */
export function mapBildebankenImageToSelectedImage(image: PublishedImage): SelectedImage {
  /*   const creator =
    image.metadata.rights?.credit ||
    (image.metadata.creators?.length && image.metadata.creators?.length > 0 // image.metadata.contributors
      ? image.metadata.creators?.join("/")
      : undefined);
 */

  const creator =
    image.metadata.rights?.credit ||
    (image.metadata.contributors?.length && image.metadata.contributors?.length > 0
      ? image.metadata.contributors
          .filter((contributor) => contributor.role.resId === ROLES.photographer)
          .map((contributor) => contributor.contact.title)
          .join("/")
      : undefined);

  const proposedCreditLine = [
    image.metadata.imageType?.replace("Fotografi", "Foto") || "Foto",
    image.metadata.creditLine || "Ukjent",
  ].join(": ");

  const credit = {
    imageType: image.metadata.imageType?.replace("Fotografi", "Foto") || undefined,
    creator: creator || undefined,
    rightsHolder: image.metadata.rights?.credit
      ? undefined
      : image.metadata.rights?.rightsHolder?.contact.title || undefined,
  };

  const publications = [{ system: "Kaleido", id: image.metadata.publicId }];

  const technicalMetadata: TechnicalMetadata = {
    mediaType: image.mediaType,
    mediaCreatedOn: image.metadata.mediaCreatedOn,
    width: image.technicalMetadata?.formData.technical_image_width,
    height: image.technicalMetadata?.formData.technical_image_height,
  };

  const selectedImage = {
    id: image.id,
    metadata: {
      ...pick(image.metadata, METADATA_FIELDS),
      credit,
      proposedCreditLine,
    },
    technicalMetadata,
    publications,
    source: "Bildebanken",
  };

  return cleanObject(selectedImage);
}

export function kaleidoImageToRightsMarker(image: Kaleido.Metadata): RightsMarker {
  if (image.usage) {
    return "Restricted";
  }

  if (image.rights === "NRK" || image.rights?.toString().startsWith("NRK ")) {
    return "Free";
  }

  if (image.rights) {
    return "Restricted";
  }

  if (image.credit === "NRK" || image.credit?.toString().startsWith("NRK ")) {
    return "Free";
  }

  if (image.credit) {
    return "Restricted";
  }

  return "Unknown";
}

export function kaleidoImageCreditLine(image: Kaleido.Metadata): string | undefined {
  if (image.rights?.match(/([\w\s]+ \/)+ [\w\s]+$/) && image.rights?.includes(image.credit)) {
    return image.rights;
  }

  if (image.creators?.length > 0 && image.credit && !image.credit.includes(" / ")) {
    const creators = image.creators.filter((creator) => !image.credit.includes(creator));
    return creators.concat(image.credit).join(" / ");
  }

  if (image.credit) {
    return cleanString(image.credit);
  }

  if (image.creators?.length > 0 && image.rights) {
    const creators = image.creators.filter((creator) => !image.rights?.includes(creator));
    return creators.concat(image.rights).join(" / ");
  }

  if (image.creators?.length > 0) {
    return image.creators.join(" / ");
  }

  return image.rights || undefined;
}

export function kaleidoImageCopyrightOwners(image: Kaleido.Metadata): string[] | undefined {
  if (!image.credit && !image.rights) {
    return;
  }

  if (image.credit) {
    return image.credit?.split(" / ");
  }

  if (image.rights) {
    return [image.rights];
  }
}

function kaleidoImageFromPolopoly(image: Kaleido.Metadata) {
  return image.origin?.toLocaleLowerCase().startsWith("no.nrk.polopoly");
}

export const cleanString = (input: string | undefined) => input && input.replace(/\0/g, "");

export function mapNtbSearchHitToSelectedImage(
  searchHit: NtbSearchHit,
  kaleidoImage: Kaleido.Metadata,
): SelectedImage {
  const image = searchHit.rawSearchHit;
  const credit = {
    imageType: undefined,
    creator: image.creator || undefined,
    rightsHolder: "NTB",
  };

  const proposedCreditLine = ["Foto", image.creditLine].join(": ");

  const technicalMetadata = {
    mediaType: kaleidoImage.format,
    mediaCreatedOn: image.dateCreated && new Date(image.dateCreated).toISOString(),
    width: kaleidoImage.width,
    height: kaleidoImage.height,
  };

  const publications = [{ id: kaleidoImage.id, system: "Kaleido" }];

  return {
    id: image.id,
    metadata: {
      title: image.headline || undefined,
      description: image.description || undefined,
      credit,
      proposedCreditLine,
    },
    technicalMetadata,
    publications,
    source: "Ntb",
  };
}

export function mapKaleidoSearchHitToSelectedImage(searchHit: KaleidoSearchHit): SelectedImage {
  const image = searchHit.rawSearchHit;

  const credit = {
    imageType: undefined,
    creator: image.creators?.join("/") || undefined,
    rightsHolder: kaleidoImageCopyrightOwners(image)?.join("/"),
  };

  const proposedCreditLine = ["Foto", kaleidoImageCreditLine(image) || "Ukjent"].join(": ");

  const technicalMetadata = {
    mediaType: image.format,
    mediaCreatedOn: image.created && new Date(image.created).toISOString(),
    width: image.width,
    height: image.height,
  };

  const publications = [{ id: image.id, system: "Kaleido" }];

  // For historic reasons, Polopoly uses 'title' for alternative text and Kaleido uses 'description'
  const uploadedInPolopoly = kaleidoImageFromPolopoly(image);
  const title = uploadedInPolopoly ? undefined : image.title;
  const description = uploadedInPolopoly ? image.description : undefined;
  const altText = uploadedInPolopoly ? image.title : image.description;

  const selectedImage = {
    id: image.id,
    metadata: {
      title,
      description,
      altText,
      credit,
      proposedCreditLine,
    },
    technicalMetadata,
    publications,
    source: "Kaleido",
  };

  return cleanObject(selectedImage);
}
