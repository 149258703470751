<script lang="ts">
  import CompactTopBar from "components/Plugin/CompactTopBar.svelte";
  import { isEmbedded } from "config";
  import Header from "../Header.svelte";

  const embedded = isEmbedded();
</script>

<div class="top">
  {#if embedded}
    <CompactTopBar authenticated={false} />
  {:else}
    <Header />
  {/if}
</div>
<div class="wrapper">
  <slot />
</div>

<style>
  .wrapper {
    padding: var(--org-medium);
  }
</style>
