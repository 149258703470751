<script lang="ts">
  import Main from "./Main.svelte";
  import Providers from "./Providers/Providers.svelte";
</script>

<div class="org-root org-theme-dark bb-theme-dark" id="bildebanken-root">
  <Providers>
    <Main />
  </Providers>
</div>

<style>
  /* Same as we have for origo-dialog */
  div.org-root {
    font:
      0.875rem/1.7 Roboto,
      sans-serif;
    -moz-osx-font-smoothing: grayscale;
    /*min-height: 100vh;*/
    display: flex;
    flex-flow: column;
    height: 100vh;
  }
</style>
