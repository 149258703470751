<script lang="ts">
  import { initPageFromUrl, onClickAnchor, onWindowPopState, pushPage } from "state/page";
  import { onDestroy, onMount } from "svelte";
  import { urlToPage } from "../../state/url-mappers";
  import { whoami } from "../../services/api";
  import { Mimir } from "bildebanken-model";
  import { getRelease } from "../../config";

  initPageFromUrl();

  let rootElement: HTMLElement | null | undefined;

  onMount(() => {
    rootElement = document.getElementById("bildebanken-root");
    rootElement?.addEventListener("click", onClickAnchor);

    // This mainly has the function of transforming unknown URL paths to "/images".
    // We wait until here to avoid conflicts with login redirects.
    pushPage(urlToPage(window.location.href));
  });

  if (getRelease() !== "dev") {
    import("@sentry/svelte").then((Sentry) => {
      const user = whoami().then((user: Mimir.User) => {
        Sentry.setUser({
          id: user.id,
          name: user.givenName + user.familyName,
          email: user.email,
        } as { id: string; name: string; email: string });
      });
    });
  }

  onDestroy(() => {
    rootElement?.removeEventListener("click", onClickAnchor);
  });
</script>

<svelte:window on:popstate={onWindowPopState} />
<slot />
