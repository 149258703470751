import { Mimir } from "bildebanken-model";
import { isValid } from "date-fns";
import { isEqual, last } from "lodash-es";

import { getBildebankenHost } from "../config";
import type { Page } from "./page";
import {
  availableSourceSystems,
  defaultDateField,
  getDefaultSearchParams,
  isDateField,
  isValidSourceSystem,
  type DateFilter,
  type PhotographerFilter,
  type SearchParams,
  type SortParams,
} from "./params";
import { getEditOnlyMode, getSelectImageOnSave } from "../services/plugin-customization";

/** Encode App state as URL (hides defaults) */
export function pageToUrl(state: Page): URL {
  const url = new URL(window.location.pathname + window.location.search, getBildebankenHost());

  switch (state.type) {
    case "LANDING_PAGE":
      url.pathname = "/";
      url.search = "";
      break;
    case "EDIT":
      url.pathname = `/edit/${state.itemIds.join(",")}`;
      url.search = "";
      break;
    case "SEARCH":
      url.pathname = "/images";
      url.search = buildSearchParams(state.searchParams);
      break;
    case "DETAIL":
      url.pathname = `/images/${
        state.itemSource && state.itemSource !== "Bildebanken"
          ? state.itemSource.toLowerCase() + "/"
          : ""
      }${state.itemId}`;
      url.search = ""; // Or include search params here?
      break;
    case "UPLOAD":
      url.pathname = "/upload/";
      url.search = "";
      break;
    default:
      url.pathname = "/";
  }

  return url;
}

/** Find out which page to show given a URL at load/reload */
export function urlToPage(href: string): Page {
  const url = new URL(href);
  const path = url.pathname;

  if (path === "/") return { type: "LANDING_PAGE" };

  const searchParams = searchFromUrl(new URLSearchParams(url.search));

  if (path === "/images" || path === "/images/") {
    return { type: "SEARCH", searchParams };
  }
  if (path.startsWith("/images/kaleido/")) {
    const parts = path.split("/");
    if (parts.length !== 4) {
      return { type: "SEARCH", searchParams, searchScrollPosition: undefined };
    }
    return {
      type: "DETAIL",
      searchParams,
      itemSource: "Kaleido",
      itemId: parts[3],
      searchScrollPosition: undefined,
    };
  }
  if (path.startsWith("/images/ntb/")) {
    const parts = path.split("/");
    if (parts.length !== 4) {
      return { type: "SEARCH", searchParams, searchScrollPosition: undefined };
    }
    return {
      type: "DETAIL",
      searchParams,
      itemSource: "Ntb",
      itemId: parts[3],
      searchScrollPosition: undefined,
    };
  }
  if (path.startsWith("/images/")) {
    const parts = path.split("/");
    if (parts.length !== 3) {
      return { type: "SEARCH", searchParams, searchScrollPosition: undefined };
    }
    return {
      type: "DETAIL",
      searchParams,
      itemId: parts[2],
      searchScrollPosition: undefined,
    };
  }
  if (path.startsWith("/upload")) {
    const parts = path.split("/");
    if (parts.length !== 3) {
      return { type: "SEARCH", searchParams, searchScrollPosition: undefined };
    }
    return {
      type: "UPLOAD",
      searchParams,
      searchScrollPosition: undefined,
    };
  }
  if (path.startsWith("/edit/")) {
    const itemIds = last(path.split("/edit/"))?.split(",");
    return {
      type: "EDIT",
      searchParams,
      itemIds: itemIds || [],
      searchScrollPosition: undefined,
      selectImageAfterEdit: getSelectImageOnSave(),
      editOnlyMode: getEditOnlyMode(),
    };
  }

  return { type: "SEARCH", searchParams: getDefaultSearchParams() };
}

function dateFilterFromUrl(params: URLSearchParams): DateFilter | undefined {
  const rawSecondsBack = params.get("secondsBack");
  const rawStartDate = params.get("startDate");
  const endDate = params.get("endDate");
  const rawDateField = params.get("dateField");
  const dateField = isDateField(rawDateField) ? rawDateField : defaultDateField;

  if (rawSecondsBack) {
    const secondsBack = Number.parseInt(rawSecondsBack);
    if (Number.isFinite(secondsBack)) {
      return {
        type: "Since",
        secondsBack,
        label: "siste " + secondsBack.toString(),
        field: dateField,
      };
    }
  } else if (rawStartDate && endDate) {
    const from = new Date(rawStartDate);
    const to = new Date(endDate);
    if (isValid(from) && isValid(to)) {
      return {
        type: "Range",
        from,
        to,
        field: dateField,
        label: `fra ${from.toLocaleDateString("no-NB")} til ${to.toLocaleDateString("no-NB")}`,
      };
    }
  }
}

function photographerFilterFromUrl(params: URLSearchParams): PhotographerFilter | undefined {
  const name = params.get("photographer");
  const id = params.get("photographerId");

  if (name && id) {
    return { name, id };
  }
  if (name) {
    return { name };
  }
}

function sortFromUrl(params: URLSearchParams): SortParams {
  const urlSortBy = params.get("sortBy");
  const urlSortOrder = params.get("sortOrder");
  const sortBy =
    urlSortBy === "mediaCreatedOn" || urlSortBy === "createdOn"
      ? urlSortBy
      : getDefaultSearchParams().sort.sortBy;
  const sortOrder =
    urlSortOrder && Mimir.isSortOrder(urlSortOrder)
      ? urlSortOrder
      : getDefaultSearchParams().sort.sortOrder;

  return {
    sortBy,
    sortOrder,
  };
}

function searchFromUrl(params: URLSearchParams): SearchParams {
  const filter = { ...getDefaultSearchParams()?.filter };
  const dateFilter = dateFilterFromUrl(params);
  const photographerFilter = photographerFilterFromUrl(params);

  if (dateFilter) {
    filter.date = dateFilter;
  }

  if (photographerFilter) {
    filter.photographer = photographerFilter;
  }

  if (params.get("rights") === "Free") {
    filter.rightsMarker = "Free";
  }

  const jobId = params.get("jobId");
  if (jobId) {
    filter.jobId = jobId;
  }

  const sourceSystems = params.get("sourceSystems");
  if (sourceSystems) {
    filter.sourceSystems = sourceSystems.split("|").filter(isValidSourceSystem);
    if (isEqual(filter.sourceSystems, availableSourceSystems)) {
      filter.sourceSystems = [];
    }
  }
  const folderId = params.get("folderId");
  if (folderId) {
    filter.folderId = folderId;
  }

  return {
    query: params.get("query") ?? getDefaultSearchParams().query,
    filter,
    sort: sortFromUrl(params),
    from: getDefaultSearchParams().from,
    owner: params.get("owner") === "current-user" ? "current-user" : undefined,
  };
}

function buildSearchParams(searchParams: SearchParams): string {
  const result = new URLSearchParams();

  if (searchParams.query) {
    result.set("query", searchParams.query);
  }

  if (searchParams.filter.date) {
    if (searchParams.filter.date.type === "Since") {
      result.set("secondsBack", searchParams.filter.date.secondsBack.toString());
    } else {
      if (searchParams.filter.date.from) {
        result.set("startDate", searchParams.filter.date.from.toISOString());
      }
      if (searchParams.filter.date.to) {
        result.set("endDate", searchParams.filter.date.to.toISOString());
      }
    }

    if (searchParams.filter.date.field !== defaultDateField) {
      result.set("dateField", searchParams.filter.date.field);
    }
  }

  result.set("sortBy", searchParams.sort.sortBy);
  result.set("sortOrder", searchParams.sort.sortOrder);

  if (searchParams.owner) {
    result.set("owner", searchParams.owner);
  }

  if (searchParams.filter.rightsMarker === "Free") {
    result.set("rights", "Free");
  }

  if (
    searchParams.filter.sourceSystems &&
    searchParams.filter.sourceSystems.length > 0 &&
    !isEqual(searchParams.filter.sourceSystems, availableSourceSystems)
  ) {
    result.set("sourceSystems", searchParams.filter.sourceSystems.join("|"));
  }

  if (searchParams.filter.photographer?.name) {
    result.set("photographer", searchParams.filter.photographer.name);
  }

  if (searchParams.filter.photographer?.id) {
    //result.set("photographerId", searchParams.filter.photographer.id);
  }

  if (searchParams.filter.jobId) {
    result.set("jobId", searchParams.filter.jobId);
  }

  if (searchParams.filter.folderId) {
    result.set("folderId", searchParams.filter.folderId);
  }

  return result.toString();
}
