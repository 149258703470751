<script lang="ts">
  import { orgRetry } from "@nrk/origo";
  import Status from "./Providers/Status.svelte";
  import StyleProvider from "./Providers/StyleProvider.svelte";

  export let message: string | undefined = undefined;
  export let reason: any | undefined = undefined;
</script>

<div class="org-root">
  <StyleProvider>
    <Status>
      <h2>Bildevelger feilet</h2>
      <p>Beklager, Bildebanken kan ikke vises akkurat nå.</p>
      {#if reason}
        <summary class="org-muted">{message}</summary>
        <details class="org-muted">{reason}</details>
      {/if}
      <button on:click={() => window.location.reload()} class="org-button org-button--secondary">
        {@html orgRetry}&nbsp;Prøv igjen
      </button>
    </Status>
  </StyleProvider>
</div>

<style>
  .org-root {
    height: 100%;
  }
  h2 {
    font-size: 1.1rem;
  }
  button {
    margin-top: var(--org-medium);
  }
</style>
