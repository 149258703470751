<script lang="ts">
  import { orgEditMetadata } from "@nrk/origo";
  import { useSelector } from "@xstate/svelte";
  import SelectionService, {
    getSelectedIds,
    getSelectedItems,
    numberOfSelectedItems,
  } from "state/SelectionService";
  import { pageState, showEdit } from "state/page";
  import { slide } from "svelte/transition";
  import { getMimirSearchHits } from "../state/queries/mimir";
  import DeleteImagesButton from "./DeleteImages/DeleteImagesButton.svelte";

  $: searchHits = getMimirSearchHits();
  $: state = $SelectionService;
  $: activeSearchSource = $pageState.type === "SEARCH" && $pageState.activeSearchResultSource;

  const selectionSize = useSelector(SelectionService, numberOfSelectedItems);
  const selectedIds = useSelector(SelectionService, getSelectedIds);
  $: selectedItems = getSelectedItems($selectedIds, $searchHits);
</script>

{#if state.matches("ItemsSelected")}
  <div class="footer" transition:slide|local={{ duration: 200 }}>
    <span class="selected-text">
      {$selectionSize}
      {`bilde${$selectionSize > 1 ? "r" : ""}`} valgt
    </span>
    <button
      class="org-button org-button--hidden-small org-button--icon-left"
      disabled={activeSearchSource === "Ntb"}
      on:click|preventDefault|stopPropagation={() => showEdit($selectedIds)}
    >
      {@html orgEditMetadata} Rediger metadata
    </button>
    <DeleteImagesButton {selectedItems} />
  </div>
{/if}

<style>
  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: var(--org-color-gray-700);
    color: var(--org-color-white);
    height: 50px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    box-shadow: 0px -6px 20px rgba(0, 0, 0, 0.8);
  }

  .selected-text {
    font-style: italic;
    margin-right: 10px;
  }
</style>
