import { type Image, type ImageType } from "bildebanken-model";
import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInHours,
  differenceInMilliseconds,
  differenceInYears,
  format,
  isFuture,
  isToday,
  isValid,
  isYesterday,
} from "date-fns";
import nbLocale from "date-fns/locale/nb";
import { first, isEqual, last, upperFirst } from "lodash-es";
import type { SearchHit } from "services/searchHit";
import type { Readable } from "svelte/store";
import { derived, get } from "svelte/store";

// Mimir sets mediaCreatedOn automatically to the timestamp of the ingest if not present
const creationToIngestTolerance = 1000;

export function hasImageCreatedDate(image: Image | SearchHit) {
  const mediaCreatedOn = image.mediaCreatedOn && new Date(image.mediaCreatedOn);
  let itemCreatedOn: Date | undefined;
  if ("createdOn" in image) {
    itemCreatedOn = new Date(image.createdOn);
  } else if ("itemCreatedOn" in image && image.itemCreatedOn) {
    itemCreatedOn = new Date(image.itemCreatedOn);
  }
  return (
    itemCreatedOn &&
    mediaCreatedOn &&
    Math.abs(differenceInMilliseconds(itemCreatedOn, mediaCreatedOn)) > creationToIngestTolerance
  );
}

export function showCount(count: number) {
  return count >= 10000 ? "10 000+" : count?.toLocaleString("no-nb");
}
export function formatRelativeDate(date: Date, timeZone?: string): string | null {
  if (!date || !isValid(date)) {
    return null;
  }

  if (isFuture(date)) {
    const now = Date.now();
    const hoursFromNow = differenceInHours(date, now);
    const daysFromNow = differenceInCalendarDays(date, now);
    const yearsFromNow = differenceInYears(date, now);
    if (hoursFromNow < 24) {
      return `Om ${hoursFromNow} timer 🛸`;
    } else if (daysFromNow < 1000) {
      return `Om ${daysFromNow} dager 🛸`;
    } else if (yearsFromNow < 100) {
      return `Om ${yearsFromNow.toLocaleString()} år 🛸`;
    } else {
      return "I framtiden 🛸";
    }
  } else if (isToday(date)) {
    return date.toLocaleTimeString("no-NB", { timeStyle: "short", timeZone });
  } else if (isYesterday(date)) {
    return `I går ${date.toLocaleTimeString("no-NB", { timeStyle: "short", timeZone })}`;
  } else if (differenceInCalendarDays(Date.now(), date) < 7) {
    return `${upperFirst(format(date, "eeee", { locale: nbLocale }))} 
      ${date.toLocaleTimeString("no-NB", { timeStyle: "short", timeZone })}`;
  } else if (differenceInCalendarMonths(Date.now(), date) < 6) {
    return format(date, "do MMMM", { locale: nbLocale });
  } else {
    return format(date, "do MMM yyyy", { locale: nbLocale });
  }
}

// From https://gist.github.com/lanqy/5193417#gistcomment-3874119
export function bytesToSize(bytes: number, maximumFactionDigits?: number) {
  const units = ["byte", "kilobyte", "megabyte", "gigabyte", "terabyte", "petabyte"];
  const abbreviatedUnit = ["B", "kB", "MB", "GB", "TB", "PB"];
  const unit = Math.floor(Math.log(bytes) / Math.log(1000));
  if (maximumFactionDigits === undefined) {
    maximumFactionDigits = unit > 1 ? 1 : 0;
  }

  try {
    // Modern browser formatting
    return new Intl.NumberFormat(undefined, {
      style: "unit",
      unit: units[unit],
      maximumFractionDigits: maximumFactionDigits,
    }).format(bytes / 1000 ** unit);
  } catch (exception) {
    // Legacy brower fallback (Chrome < 77, Safari < 14.1)
    return new Intl.NumberFormat(undefined, {
      maximumFractionDigits: maximumFactionDigits,
    })
      .format(bytes / 1000 ** unit)
      .concat(" ", abbreviatedUnit[unit]);
  }
}

export function anyValues(object: Record<string, unknown>) {
  return Object.values(object).some((value) => value !== undefined);
}

export function wait(delay: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export function getErrorMessage(error: unknown): string {
  let message = "Ukjent feil";

  if (
    error &&
    typeof error === "object" &&
    "message" in error &&
    typeof error.message === "string"
  ) {
    message = error.message;
  }

  return message;
}

/**
 * Creates a derived store that only emits new values if the derived value has changed.
 *
 * Comparison of old and new values is done using lodash isEqual.
 *
 * @param store Input store
 * @param map Function to transform store value
 */
export function diffingDerivedStore<T, U>(store: Readable<T>, map: (value: T) => U): Readable<U> {
  let prev = map(get(store));

  return derived(
    store,
    (value, set) => {
      const newValue = map(value);

      if (!isEqual(prev, newValue)) {
        prev = newValue;
        set(newValue);
      }
    },
    prev,
  );
}

export function getEnvironmentFromHostname(hostname: string): string {
  const subdomain = first(hostname.split("."));
  if (!subdomain) return "stage";
  const mapping = { bildebanken: "prod", localhost: "dev" };
  return mapping[subdomain] || last(subdomain.split("-"));
}

export function mapDigitalSourceTypeToImageType(digitalSourceType: string): ImageType | undefined {
  const splitUrl = digitalSourceType.split("/");
  const sourceTypeId = splitUrl[splitUrl.length - 1];
  switch (sourceTypeId) {
    case "digitalCapture":
    case "negativeFilm":
    case "positiveFilm":
    case "print":
    case "minorHumanEdits":
    case "compositeCapture":
    case "algorithmicallyEnhanced":
      return "Fotografi";
    case "dataDrivenMedia":
    case "digitalArt":
    case "softwareImage":
      return "Grafikk";
    case "compositeSynthetic":
    case "virtualRecording":
    case "trainedAlgorithmicMedia":
    case "compositeWithTrainedAlgorithmicMedia":
    case "algorithmicMedia":
      return "KI-generert";
    case "digitalCreation":
      return "Illustrasjon";
    case "digitalFacsimile":
      return "Faksimile";
    default:
      console.warn("Feil i kildetype", sourceTypeId);
      return;
  }
}

export function mapImageTypeToDigitalSourceType(imageType: ImageType): string | undefined {
  let digitalSourceType;
  switch (imageType) {
    case "Fotografi":
      digitalSourceType = "digitalCapture";
      break;
    case "Grafikk":
      digitalSourceType = "digitalArt";
      break;
    case "Faksimile":
      digitalSourceType = "digitalFacsimile";
      break;
    case "Illustrasjon":
      digitalSourceType = "digitalCreation";
      break;
    case "KI-generert":
      digitalSourceType = "compositeSynthetic";
      break;
    default: {
      console.warn("Feil bildetype", imageType);
      return;
    }
  }
  if (digitalSourceType === "digitalFacsimile") {
    return new URL(`https://authority.nrk.no/datadictionary/${digitalSourceType}`).toString();
  }

  return new URL(`http://cv.iptc.org/newscodes/digitalsourcetype/${digitalSourceType}`).toString();
}

export const uuidRe = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

/**
 * Strip description of NTB pictures for redundant metadata.
 *
 * @param description Raw description for a NTB picture.
 * @returns Clean description without location, date and byline.
 */
export function stripNtbDescription(description: string): string {
  const locationDateReg = /^(\S+\s+)+\d{8}\./;
  const bylineReg = /Foto:\s?(\S+\s?)+\/\s?\S+\s?$/;

  const stripped = description.replace(locationDateReg, "").replace(bylineReg, "").trim();
  return stripped;
}
