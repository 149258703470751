import * as configcat from "configcat-js";
import { getAccount } from "auth/auth";
import { getSystem } from "./plugin-customization";
import { isEmbedded } from "config";

const CONFIG_CAT_KEY = "configcat-sdk-1/tzLcCB0uu0iFXxtXcZcBhA/LUtMYGwaFEKHsQVdcN92ZQ";

let configCatInstance: configcat.IConfigCatClient | undefined = undefined;

export function initializeConfigCat() {
  configCatInstance = configcat.getClient(CONFIG_CAT_KEY, configcat.PollingMode.AutoPoll, {
    dataGovernance: configcat.DataGovernance.EuOnly,
  });
  return configCatInstance;
}

function getConfigCatInstance(): configcat.IConfigCatClient {
  return configCatInstance ?? initializeConfigCat();
}
function createConfigCatUserObject(): configcat.User | undefined {
  const user = getAccount();
  if (!user || !user.username) {
    return undefined;
  }
  const groups = user?.groups || [];

  const userAttributes: { [key: string]: configcat.UserAttributeValue } = {
    groups,
    isEmbedded: isEmbedded().toString(),
  };

  if (!isEmbedded()) {
    userAttributes.system = "bildebanken-standalone";
  } else {
    userAttributes.system = getSystem() ?? "unknown";
  }

  return new configcat.User(
    user.username.toLowerCase(),
    user.email?.toLowerCase(),
    undefined,
    userAttributes,
  );
}

export async function useFeatureToggle(key: FeatureToggles): Promise<boolean> {
  const configCatClient = getConfigCatInstance();
  const user = createConfigCatUserObject();
  const toggle = await configCatClient.getValueAsync(key, false, user);
  return toggle;
}

type FeatureToggles =
  | "suggestAltText"
  | "showRightsInPluginTiles"
  | "showRelatedImagesStandalone"
  | "showMoreInCompactDetailsPlugin"
  | "searchOnEveryKeyEvent"
  | "showFolders"
  | "ntbSearch";
