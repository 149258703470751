import { apiFetch } from "../fetch-wrapper";
import { getApiUrl } from "config";

export async function getGeneratedAltText(
  itemId: string,
): Promise<{ caption: { nb: string; en: string } }> {
  const response = await apiFetch(`${getApiUrl()}/images/${itemId}/caption`, {
    method: "GET",
  });

  if (!response.ok) {
    if (response.status === 403) {
      throw Error("No access to this page");
    }
    throw Error("Could not get caption for item " + itemId);
  }

  return response.json();
}
