<script lang="ts">
  import "@nrk/core-design-tokens/nrk-color-tokens.css";
</script>

<div class="wrapper">
  <slot />
</div>

<style>
  :global(.org-input) {
    background-color: var(--color-surface-primary);
    color: var(--color-text-primary);
  }

  :global(bb-expand) {
    background-color: var(--color-surface-secondary);
    color: var(--color-text-primary);
  }

  :global(table) {
    color: var(--color-text-primary);
  }

  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--color-surface-primary);
    color: var(--color-text-primary);

    --bb-mobile-max-size: 1024px;
    --bb-selected-bg-color: hsla(219, 100%, 55%, 0.4);
    --bb-selected-hover-bg-color: hsla(219, 90%, 55%, 0.5);
    --bb-tile-bg-color: var(--org-color-gray-700);
    --bb-standard-border-radius: 3px;
  }

  .wrapper :global(.overflow-ellipsis) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }

  .wrapper :global(*) {
    box-sizing: border-box;
  }

  :global(.wrapper a),
  :global(.org-root a:not([class])) {
    color: inherit;
    text-decoration: none;
  }

  :global(.wrapper a:not(.org-button):hover, .wrapper a:not(.org-button):hover) {
    text-decoration: underline;
  }

  :global(.productfruits--container) {
    font-family: sans-serif;
  }

  .wrapper {
    --nrk-color-black-rgb: 0, 0, 0;
    --nrk-color-black: rgb(var(--nrk-color-black-rgb));
    --nrk-color-white-rgb: 255, 255, 255;
    --nrk-color-white: rgb(var(--nrk-color-white-rgb));

    /* Dark definitions */
    --color-surface-primary-dark: var(--nrk-color-black);
    --color-surface-primary-dark-rgb: var(--nrk-color-black-rgb);
    --color-surface-secondary-dark: var(--nrk-color-gray-950);
    --color-surface-tertiary-dark: var(--nrk-color-gray-900);
    --color-surface-invert-dark: var(--nrk-color-gray-400);
    --color-surface-warning-dark: var(--nrk-color-red-800);

    --color-text-primary-dark: var(--nrk-color-white);
    --color-text-secondary-dark: var(--nrk-color-gray-100);
    --color-text-inactive-dark: var(--nrk-color-gray-400);
    --color-text-invert-dark: var(--nrk-color-black);

    --color-logo-primary-dark: var(--nrk-color-gray-200);
    --color-logo-secondary-dark: var(--nrk-color-black);

    /* Light definitions */
    --color-surface-primary-light: var(--nrk-color-white);
    --color-surface-primary-light-rgb: var(--nrk-color-white-rgb);
    --color-surface-secondary-light: var(--nrk-color-gray-100);
    --color-surface-tertiary-light: var(--nrk-color-gray-200);
    --color-surface-invert-light: var(--nrk-color-gray-500);
    --color-surface-warning-light: var(--nrk-color-red-200);

    --color-text-primary-light: var(--nrk-color-black);
    --color-text-secondary-light: var(--nrk-color-gray-900);
    --color-text-inactive-light: var(--nrk-color-gray-600);
    --color-text-invert-light: var(--nrk-color-white);

    --color-logo-primary-light: var(--nrk-color-gray-900);
    --color-logo-secondary-light: var(--nrk-color-white);
  }

  /* Use client prefered color scheme as default */
  @media (prefers-color-scheme: dark) {
    .wrapper {
      color-scheme: dark;
      --color-surface-primary: var(--color-surface-primary-dark);
      --color-surface-primary-rgb: var(--color-surface-primary-dark-rgb);
      --color-surface-secondary: var(--color-surface-secondary-dark);
      --color-surface-tertiary: var(--color-surface-tertiary-dark);
      --color-surface-invert: var(--color-surface-invert-dark);
      --color-surface-warning: var(--color-surface-warning-dark);

      --color-text-primary: var(--color-text-primary-dark);
      --color-text-secondary: var(--color-text-secondary-dark);
      --color-text-inactive: var(--color-text-inactive-dark);
      --color-text-invert: var(--color-text-invert-dark);

      --color-logo-primary: var(--color-logo-primary-dark);
      --color-logo-secondary: var(--color-logo-secondary-dark);
    }
  }
  @media (prefers-color-scheme: light) {
    .wrapper {
      color-scheme: light;
      --color-surface-primary: var(--color-surface-primary-light);
      --color-surface-primary-rgb: var(--color-surface-primary-light-rgb);
      --color-surface-secondary: var(--color-surface-secondary-light);
      --color-surface-tertiary: var(--color-surface-tertiary-light);
      --color-surface-invert: var(--color-surface-invert-light);
      --color-surface-warning: var(--color-surface-warning-light);

      --color-text-primary: var(--color-text-primary-light);
      --color-text-secondary: var(--color-text-secondary-light);
      --color-text-inactive: var(--color-text-inactive-light);
      --color-text-invert: var(--color-text-invert-light);

      --color-logo-primary: var(--color-logo-primary-light);
      --color-logo-secondary: var(--color-logo-secondary-light);
    }
  }

  /* Override color scheme if class is set by embedding app */
  :global(.bb-theme-dark) {
    & .wrapper {
      color-scheme: dark;
      --color-surface-primary: var(--color-surface-primary-dark);
      --color-surface-primary-rgb: var(--color-surface-primary-dark-rgb);
      --color-surface-secondary: var(--color-surface-secondary-dark);
      --color-surface-tertiary: var(--color-surface-tertiary-dark);
      --color-surface-invert: var(--color-surface-invert-dark);
      --color-surface-warning: var(--color-surface-warning-dark);

      --color-text-primary: var(--color-text-primary-dark);
      --color-text-secondary: var(--color-text-secondary-dark);
      --color-text-inactive: var(--color-text-inactive-dark);
      --color-text-invert: var(--color-text-invert-dark);

      --color-logo-primary: var(--color-logo-primary-dark);
      --color-logo-secondary: var(--color-logo-secondary-dark);
    }
  }
  :global(.bb-theme-light) {
    & .wrapper {
      color-scheme: light;
      --color-surface-primary: var(--color-surface-primary-light);
      --color-surface-primary-rgb: var(--color-surface-primary-light-rgb);
      --color-surface-secondary: var(--color-surface-secondary-light);
      --color-surface-tertiary: var(--color-surface-tertiary-light);
      --color-text-invert: var(--color-text-invert-light);
      --color-surface-warning: var(--color-surface-warning-light);

      --color-text-primary: var(--color-text-primary-light);
      --color-text-secondary: var(--color-text-secondary-light);
      --color-text-inactive: var(--color-text-inactive-light);

      --color-logo-primary: var(--color-logo-primary-light);
      --color-logo-secondary: var(--color-logo-secondary-light);
    }
  }
</style>
