<svelte:options immutable />

<script lang="ts">
  import type { SearchHit } from "services/searchHit";
  import type { SearchHitHandler } from "./searchHitHandler";

  export let searchHit: SearchHit;
  export let selected: boolean;
  export let handler: SearchHitHandler;
  export let preloader = () => Promise.resolve();

  // embedding code should bind to these two
  export let focus: boolean;

  function onCheckBoxChange(this: HTMLInputElement) {
    handler.onToggleCheckbox({
      searchHitId: searchHit.id,
      checked: this.checked,
    });
  }
</script>

<label class="org-sr">
  <!-- svelte-ignore a11y-unknown-role -->
  <span role="text">Velg {searchHit.metadata.title}</span>
  <input
    type="checkbox"
    class="org-input"
    name="select-image"
    checked={selected}
    on:click|stopPropagation
    on:change|stopPropagation|preventDefault={onCheckBoxChange}
    on:focus={() => {
      focus = true;
      preloader();
    }}
    on:blur={() => (focus = false)}
  />
</label>
