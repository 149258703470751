<script lang="ts">
  import { orgMagnifier } from "@nrk/origo";
  import { navigateToSearch, searchText } from "state/page";
  import ScrollableImageStack from "./ScrollableImageStack.svelte";
  import { featuredImages, type BackgroundImage } from "./featuredImages";

  const handleSearch = () => {
    navigateToSearch(searchInputValue);
  };

  let backgroundImage: BackgroundImage | undefined = undefined;

  const handleImageChange = (event: Event & { detail: { image: BackgroundImage } }) => {
    backgroundImage = event.detail.image;
  };

  let searchInputEl: HTMLInputElement;
  let searchInputFocus = false;
  let searchInputValue = $searchText || "";

  const images = featuredImages;

  // Pseudo-random "image of the day"
  const imagesOffset = new Date().getDay() - 1;
</script>

<div class="wrapper">
  <ScrollableImageStack {images} {imagesOffset} on:change={handleImageChange}>
    <svelte:fragment slot="overlay-flex">
      <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-positive-tabindex -->
      <form
        role="search"
        on:submit|preventDefault={handleSearch}
        on:click={() => searchInputEl.focus()}
      >
        <div class="search" class:focus={searchInputFocus}>
          <span class="icon">
            {@html orgMagnifier}
          </span>
          <input
            type="text"
            bind:this={searchInputEl}
            bind:value={searchInputValue}
            on:focus={() => (searchInputFocus = true)}
            on:blur={() => (searchInputFocus = false)}
            placeholder="Finn et bilde…"
            enterkeyhint="search"
            aria-label="Søk"
            tabindex="1"
            data-testid="search-bar"
          />
          <button
            type="submit"
            class="org-button org-button--primary"
            class:org-sr={searchInputValue.length === 0}
            aria-label="Søk"
            data-testid="search-button"
          >
            <span aria-hidden="true" class="enter">↩</span>
          </button>
        </div>
      </form>
      {#if backgroundImage}
        <div class="byline">
          <a href="/images/{backgroundImage?.id}">
            Foto: {backgroundImage?.byline || "Ukjent"}
          </a>
        </div>
      {/if}
    </svelte:fragment>
  </ScrollableImageStack>
</div>

<style>
  .wrapper {
    width: 100%;
    height: 100%;
    background: var(--color-surface-tertiary);
  }

  .search {
    --background-transparency: 0.7;
    background: rgb(255, 255, 255, var(--background-transparency));
    height: 45px;
    width: 400px;
    border: 1px gray solid;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 5px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(3px);
    --shadow-color: 221deg 11% 10%;
    box-shadow:
      0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.3),
      1px 1.9px 2.6px -0.6px hsl(var(--shadow-color) / 0.35),
      2.3px 4.5px 6.2px -1.2px hsl(var(--shadow-color) / 0.4),
      5.3px 10.6px 14.5px -1.8px hsl(var(--shadow-color) / 0.45);
  }

  @media (prefers-reduced-transparency) {
    .search {
      --background-transparency: 0.9;
    }
  }

  .search .icon {
    color: gray;
    margin-left: 0.75em;
    margin-right: 0.25em;
    background: transparent;
    font-size: 0.75rem;
  }

  /* WebKit (Safari), Blink (Chromium) */
  .search:has(input:focus) {
    outline: 2px var(--org-color-focus) solid;
  }

  /* Gecko (Firefox) */
  .search.focus {
    outline: 2px var(--org-color-focus) solid;
  }

  .search input {
    color: black;
    background: transparent;
    flex: 1;
    font-size: 0.9rem;
    border: none;
  }

  .search input::placeholder {
    color: #333;
    opacity: 0.75;
  }

  .search input:focus {
    outline: none;
  }

  .search button .enter {
    font-weight: 500;
    display: inline-block;
    margin-top: 5px;
  }

  .byline {
    font-weight: 400;
    position: absolute;
    bottom: 1em;
    right: 1em;
    color: #ccc;
    mix-blend-mode: plus-lighter;
  }

  a {
    color: var(--color-text-primary);
  }
</style>
