<script lang="ts">
  import { getKaleidoSearchQuery } from "state/queries/kaleido";
  import { getMimirSearchQuery } from "state/queries/mimir";

  const query = getMimirSearchQuery();
  const kaleidoQuery = getKaleidoSearchQuery();

  $: zero =
    ($query.data?.pages[0]?.hits?.length === 0 || $query.data?.pages[0]?.total === 0) &&
    !kaleidoCount;
  $: count = $query.data?.pages[0]?.total;
  $: kaleidoCount = $kaleidoQuery.data?.pages[0]?.total;
  $: combinedCount = (count || 0) + (kaleidoCount || 0);

  $: results = !$query.isLoading && !($query.isRefetching && count === 0) && count !== undefined;
  $: busy = $query.isLoading || $query.isRefetching;

  const formatter = Intl.NumberFormat("nb");
  $: formattedCount =
    combinedCount && (kaleidoCount === 10000 ? "10 000+" : formatter.format(combinedCount));
</script>

<!-- svelte-ignore a11y-unknown-role -->
<div class="wrapper" role="text">
  {#if results && !combinedCount}
    <!-- Avoid "NaN" if no count -->
    {""}
  {:else if results || (zero && !busy)}
    <span>{zero ? "Ingen" : formattedCount} bilde{combinedCount !== 1 ? "r" : ""}</span>
  {:else}
    Finner bilder…
  {/if}
  {#if busy}
    <span class="org-spinner" />
  {/if}
</div>

<style>
  .wrapper {
    display: flex;
    gap: var(--org-medium);
    white-space: nowrap;
  }

  .org-spinner {
    font-size: 0.33rem;
  }
</style>
