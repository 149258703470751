<script lang="ts">
  import { searchFilter, updateSearchFilter } from "state/page";

  function onChange(this: HTMLInputElement) {
    if (this.checked) {
      updateSearchFilter("rightsMarker", "Free");
    } else {
      updateSearchFilter("rightsMarker");
    }
  }
</script>

<label>
  <input
    type="checkbox"
    class="org-input"
    checked={$searchFilter.rightsMarker === "Free"}
    on:change={onChange}
  />
  <span>Fri gjenbruk i NRK</span>
</label>

<style>
  /** Alignment is off with default values, but shouldn't need this much tweaking */
  label {
    padding: var(--org-small) var(--org-xsmall) var(--org-small);
    display: flex;
    gap: var(--org-small);
    align-items: center;
  }
</style>
