<script lang="ts">
  import { orgPlus } from "@nrk/origo";
  import { returnToSearchPage } from "state/page";
  import { onMount } from "svelte";
  import Dialog from "../Common/Dialog.svelte";
  import DialogContent from "./DialogContent.svelte";

  export let onConfirm: (name: string) => void;

  let newFolderDialogElement: HTMLElement;
  let showNewFolderDialog = false;
  let folderName: string = "";

  function onToggle(this: HTMLElement) {
    if (this !== newFolderDialogElement) {
      return;
    } else if (this.hidden) {
      returnToSearchPage();
    }
    showNewFolderDialog = !newFolderDialogElement.hidden;
    folderName = "";
  }

  onMount(() => {
    newFolderDialogElement.addEventListener("dialog.toggle", onToggle);

    return () => {
      newFolderDialogElement.removeEventListener("dialog.toggle", onToggle);
    };
  });
</script>

<button
  class="org-button org-button--hidden-large org-button--icon-left"
  data-for="new-folder-dialog"
>
  {@html orgPlus}
  Ny mappe
</button>
<bb-dialog id="new-folder-dialog" hidden bind:this={newFolderDialogElement}>
  <!--  We hide the dialog with this boolean so we can use the Svelte lifecycle to start and stop machine-->
  {#if showNewFolderDialog}
    <Dialog
      confirmText={"Lag ny mappe"}
      headerText={"Ny mappe"}
      onConfirm={() => onConfirm(folderName)}
    >
      <DialogContent bind:folderName />
    </Dialog>
  {/if}
</bb-dialog>

<style>
  bb-dialog {
    width: 700px;
    max-width: 90vw;
  }
</style>
