<script lang="ts">
  import { glassPaneState } from "./GlassPane";

  function cancel() {
    glassPaneState.set({ state: "HIDDEN" });
  }
</script>

{#if $glassPaneState.state !== "HIDDEN"}
  <div class="wrapper">
    <div style="text-align:center">
      <h2 tabIndex="-1">{$glassPaneState.message}</h2>
      {#if $glassPaneState.details}
        <p class:org-warning={$glassPaneState.state === "SHOW_ERROR"}>{$glassPaneState.details}</p>
      {/if}
      {#if $glassPaneState.state === "VISIBLE"}
        <div class="org-spinner" style="height:80px" />
      {/if}
      <button type="button" class="org-button org-button--secondary" on:click={cancel}>
        Avbryt
      </button>
    </div>
  </div>
{/if}

<style>
  .wrapper {
    backdrop-filter: blur(2.5px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(114, 114, 114, 0.7);
    position: absolute;
    z-index: 10;
  }
  .org-warning {
    display: block;
  }
</style>
