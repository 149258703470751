import SelectionService from "state/SelectionService";

type NavigationParams = {
  openDetailsForItem: (id: string) => void;
  selectionService: typeof SelectionService;
};

// Creates an event handler for the given params
export function itemOnKeydown(param: NavigationParams) {
  return function (this: HTMLElement, event: KeyboardEvent) {
    const itemId = this.id;
    const selected = this.dataset.selected === "true";

    // scrollIntoViewIfNeeded not supported in Firefox
    let nextEl:
      | (Element & { scrollIntoViewIfNeeded?: (options: ScrollIntoViewOptions) => void })
      | null = null;

    switch (event.code) {
      case "Escape": {
        param.selectionService.send({ type: "SELECT_ITEMS", itemIds: [] });
        break;
      }
      case "Enter": {
        param.openDetailsForItem(itemId);
        break;
      }

      case "Space": {
        if (event.shiftKey) {
          param.selectionService.send({ type: "ITEM_CLICKED", itemId, event });
        }
        break;
      }

      case "ArrowRight":
      case "ArrowLeft": {
        if (event.code === "ArrowRight") {
          nextEl = this.nextElementSibling;
        } else {
          nextEl = this.previousElementSibling;
        }

        if (event.shiftKey && nextEl instanceof HTMLElement) {
          if (nextEl.dataset.selected !== "true") {
            param.selectionService.send({
              type: "EXTEND_SELECTION",
              itemIds: [itemId, nextEl.id],
            });
          } else if (selected) {
            param.selectionService.send({ type: "REMOVE_FROM_SELECTION", itemIds: [itemId] });
          }
        }

        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        // prevent scrolling, this is handled manually below
        event.preventDefault();

        // we're either in a grid or a table
        const numberOfColumns =
          (this.parentElement &&
            window.getComputedStyle(this.parentElement)["grid-template-columns"]?.split(" ")
              .length) ||
          1;

        // the line below is a valid case of breaking this rule
        //eslint-disable-next-line @typescript-eslint/no-this-alias
        nextEl = this;
        const ids = [itemId];

        if (event.code === "ArrowUp") {
          for (let i = 0; i < numberOfColumns && nextEl; ++i) {
            nextEl = nextEl.previousElementSibling;
            nextEl?.id && ids.push(nextEl.id);
          }
        } else {
          for (let i = 0; i < numberOfColumns && nextEl; ++i) {
            nextEl = nextEl.nextElementSibling;
            nextEl?.id && ids.push(nextEl.id);
          }
        }

        if (event.shiftKey && nextEl instanceof HTMLElement) {
          if (nextEl.dataset.selected !== "true") {
            param.selectionService.send({ type: "EXTEND_SELECTION", itemIds: ids });
          } else {
            param.selectionService.send({
              type: "REMOVE_FROM_SELECTION",
              itemIds: ids.filter((id) => id !== nextEl!.id),
            });
          }
        }
        break;
      }
      default:
        break;
    }

    // handle focus and scrolling
    if (nextEl) {
      nextEl.querySelector<HTMLInputElement>("input[name=select-image]")?.focus();
      if ("scrollIntoViewIfNeeded" in nextEl) {
        nextEl.scrollIntoViewIfNeeded?.({ behavior: "smooth", block: "center" });
      } else {
        nextEl.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  };
}
