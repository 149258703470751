<script lang="ts">
  import type { Image } from "bildebanken-model";
  import type { EditImageActor } from "./editImageMachine";
  import { getImageVisibility, getVisibilityOptions, type VisibilityKey } from "./visibility";

  export let service: EditImageActor;
  export let image: Image;
  export let editOnlyMode: boolean = false;
  export let selectImageOnSave: boolean = false;

  const initialValue = getImageVisibility(image);

  const options = getVisibilityOptions();

  function onSelectVisibility(this: HTMLSelectElement & { value: VisibilityKey }) {
    service.send({ type: "EDIT_VISIBILITY", value: options[this.value]!.value });
  }

  const allowed = !editOnlyMode && !selectImageOnSave;
</script>

<label>
  Synlighet
  <select class="org-input" on:change={onSelectVisibility} value={initialValue}>
    {#each Object.entries(options) as [key, option]}
      <option disabled={!allowed && !option.label.includes("alle")} value={key}>
        {option.label}
      </option>
    {/each}
  </select>
</label>
