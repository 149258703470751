import { writable } from "svelte/store";

export type ViewType = "TILES" | "TABLE";

export function isViewType(viewType: string | null): viewType is ViewType {
  return viewType === "TILES" || viewType === "TABLE";
}

const viewTypeKey = "bildebanken-view-type";
const initialValue = localStorage.getItem(viewTypeKey);
export const currentViewType = writable<ViewType>(
  isViewType(initialValue) ? initialValue : "TILES",
);

currentViewType.subscribe((value) => {
  if (isViewType(value)) {
    localStorage.setItem(viewTypeKey, value);
  }
});
