import axios, { type AxiosProgressEvent } from "axios";
import type { Image, ImagePatch, Mimir } from "bildebanken-model";
import { isEqual, omit } from "lodash-es";

import { addHours } from "date-fns";
import type { SearchFilter, SearchParams, SortParams } from "state/params";
import { PAGE_SIZE, getDefaultSearchParams } from "state/params";
import { getApiUrl } from "../config";
import { withRetry } from "../utils/retry";
import { apiFetch } from "./fetch-wrapper";
import type { MimirSearchHit, SuccessfulSearchResult } from "./searchHit";
import { mapToSearchHits, mimirResultToSearchHit } from "./searchHit";
import { getKaleidoMetadata } from "./kaleido";
import type { PublishedImage } from "components/Plugin/imageTypes";

function mapFilter(filter: SearchFilter): Mimir.SearchFilters {
  const { date, rightsMarker, sourceSystems, photographer, jobId, folderId } = filter;
  const mimirFilter: Mimir.SearchFilters = {};

  if (date) {
    if (date.type === "Since") {
      const now = new Date();
      const since = new Date(now.valueOf() - date.secondsBack * 1000);
      mimirFilter["default_" + date.field] = `[${since.toISOString()} to ${now.toISOString()}]`;
    }
    if (date.type === "Range" && date.to && date.from) {
      mimirFilter[
        "default_" + date.field
      ] = `[${date.from.toISOString()} to ${date.to.toISOString()}]`;
    }
  }

  if (jobId) {
    mimirFilter["jobId"] = jobId;
  }

  if (rightsMarker) {
    mimirFilter["rightsMarker"] = rightsMarker;
  }

  if (sourceSystems?.length) {
    mimirFilter["sourceSystem"] = sourceSystems.join("|");
  }

  if (photographer?.name) {
    //Search on a single name only works without quotes, search on full name only works with quotes.
    const quoteIfSpaces = (text: string) => (text.includes(" ") ? `"${text}"` : text);
    //This is where the label goes from "photographer" to "creators".
    mimirFilter["creators"] = quoteIfSpaces(photographer.name);
  }

  if (folderId) {
    mimirFilter["folderId"] = folderId;
  }

  return mimirFilter;
}

function mapSort(sort: SortParams): Mimir.SortParams {
  const { sortOrder, sortBy } = sort;
  let sortProperty: Mimir.SortableProperty;
  switch (sortBy) {
    case "createdOn": {
      sortProperty = "item_created_date";
      break;
    }
    case "mediaCreatedOn": {
      sortProperty = "media_created_date";
      break;
    }
    case "modifiedOn": {
      sortProperty = "modifiedOn";
      break;
    }
    default:
      sortProperty = "media_created_date";
  }

  return { sortProperty, sortOrder };
}

export function extractNtbIdFromUrl(url: string) {
  const ntb = /^https:\/\/bilder\.ntb\.no\/r\/preview\/editorial\/([a-zA-Z0-9_-]+)(.*)$/;
  const ntbUrl = url.match(ntb);
  if (ntbUrl) {
    const id = ntbUrl[1];
    return id;
  }
  return undefined;
}

export function extractKaleidoIdFromUrl(url: string) {
  const kaleidoPattern =
    /^https:\/\/kaleido(-[^.]+)?\.nrk\.no\/service\/api\/1\.0\/data\/([^/]{22})$/;
  const kaleidoUrl = url.match(kaleidoPattern);
  if (kaleidoUrl) {
    return kaleidoUrl[2];
  }
  const gfxPattern = /^https:\/\/gfx(-[^.]+)?\.nrk\.no\/([^/]{44})(.*)$/;
  const gfxUrl = url.match(gfxPattern);
  if (gfxUrl) {
    return gfxUrl[2].substring(0, 22);
  }
  return undefined;
}

function buildQueryString(query: string, { filter }: SearchParams): string {
  const filters = Object.entries(mapFilter(filter)).map(([field, value]) => `${field}:${value}`);
  const filtersString = filters.join(" ");
  const kaleidoId = extractKaleidoIdFromUrl(query);
  if (kaleidoId) {
    query = kaleidoId;
  }
  return `${query ? query + " " : ""}${filtersString ? filtersString + " " : ""}`;
}

const futureDateThresholdHours = 48;

const excludeFutureDates: SearchFilter = {
  date: {
    field: "mediaCreatedOn",
    label: "",
    type: "Range",
    from: new Date("0001-01-01"),
    to: addHours(Date.now(), futureDateThresholdHours),
  },
};

/**
 * Search for Images
 *
 * @param {SearchParams} params Search params
 * @param {AbortSignal} [signal] To abort the request
 * @returns `void` if the request is cancelled via the optional signal
 */
export async function search(
  params: SearchParams,
  signal?: AbortSignal,
): Promise<SuccessfulSearchResult<MimirSearchHit>> {
  const { query, sort, from, owner } = params;

  const searchParamsTouched = !isEqual(params, getDefaultSearchParams());
  // Exclude future dates in initial search,
  // if no parameters are changed from default
  const searchParams = searchParamsTouched ? params : { ...params, filter: excludeFutureDates };
  const searchQuery = buildQueryString(query, searchParams);
  const url = new URL(getApiUrl() + "/search");

  applySortOptions(url, sort, from);

  if (params.filter.folderId) {
    url.searchParams.append("folderId", params.filter.folderId);
  }
  url.searchParams.append("search", searchQuery);

  if (owner) {
    url.searchParams.append("owner", owner);
  }

  const response = await apiFetch(url, {
    method: "GET",
    signal,
  });

  if (!response.ok) {
    throw Error("Invalid response from search API: " + response.status);
  }

  const body: Mimir.SearchResponse = await response.json();

  if (!("total" in body) || !body._embedded?.collection) {
    throw Error("Invalid data format from search API");
  }

  return {
    total: body.typeCount?.find((type) => type.key === "image")?.doc_count ?? (body.total || 0),
    hits: mapToSearchHits(body._embedded.collection, mimirResultToSearchHit),
    from: params.from,
  };
}

function applySortOptions(url: URL, sort: SortParams, from: number) {
  const mappedSort = mapSort(sort);
  url.searchParams.append("itemsPerPage", PAGE_SIZE.toString());
  url.searchParams.append("from", from.toString());
  if (mappedSort.sortProperty) url.searchParams.append("sortProperty", mappedSort.sortProperty);
  if (mappedSort.sortOrder) url.searchParams.append("sortOrder", mappedSort.sortOrder);
}

export async function getMetadataItem(
  itemId: string,
  itemSource?: "Bildebanken" | "Kaleido" | "NTB",
): Promise<Image> {
  const url = new URL(
    `${getApiUrl()}/images/${
      itemSource && itemSource !== "Bildebanken" ? itemSource.toLowerCase() + "/" : ""
    }${itemId}/metadata`,
  );

  const response = await apiFetch(url, {
    method: "GET",
  });

  if (!response.ok) {
    if (response.status === 403) {
      throw Error("No access to this page");
    }
    throw Error("Could not get metadata for item " + itemId);
  }

  const image = (await response.json()) as Image;

  // In case we do not have technical metadata and we have public id (KaleidoId),
  // fetch and use width/height from Kaleido API

  if (!image.technicalMetadata) {
    if (image.metadata?.publicId) {
      const metadata = await getKaleidoMetadata(image.metadata.publicId);
      if (metadata.height && metadata.width && metadata.format) {
        image.technicalMetadata = {
          formId: "",
          formData: {
            technical_image_height: metadata.height,
            technical_image_width: metadata.width,
            technical_image_file_type: metadata.format,
          },
        };
      } else {
        console.warn("Could not retrieve height/width/format for Kaleido image", image.id);
      }
    } else {
      console.warn("Non-Kaleido image with no technical metadata", image.id);
    }
  }
  return image;
}

export async function getRelations(itemId: string): Promise<unknown> {
  const response = await apiFetch(`${getApiUrl()}/images/${itemId}/relations`, {
    method: "GET",
  });

  return response.json();
}

export type ImagePatchWithId = ImagePatch & { id: string };
export async function updateMetadata(patch: ImagePatchWithId): Promise<Image> {
  const response = await apiFetch(`${getApiUrl()}/images/${patch.id}/metadata`, {
    method: "PATCH",
    body: JSON.stringify(omit(patch, "id")),
    headers: {
      "content-type": "application/json",
    },
  });

  if (!response.ok) {
    throw Error("Could not update metadata for item " + patch.id);
  }
  return response.json();
}

export type Placeholder = { uploadingUrl: string; id: string };

const fallbackMimeTypes = {
  arw: "image/arw",
  nef: "image/nef",
  dng: "image/dng",
} as const;

/**
 * Create placeholder for uploading image to.
 *
 * Returns the Mimir item ID and a URL to upload to.
 */
export async function createUploadPlaceholder(
  file: File,
  signal?: AbortSignal,
  jobId?: string,
): Promise<Placeholder> {
  // // Comment back in to test error handling
  // if (Math.random() < 0.5) {
  //   throw Error("Random error when creating placeholder");
  // }

  const fileExtension = file.name.split(".").pop();
  const contentType: string | undefined =
    file.type || (fileExtension && fallbackMimeTypes[fileExtension]);

  const response = await withRetry(() =>
    apiFetch(`${getApiUrl()}/placeholder`, {
      method: "POST",
      body: JSON.stringify({
        contentType,
        filename: file.name,
        sourceSystem: "Bildebanken",
        jobId: jobId,
      }),
      headers: { "content-type": "application/json" },
      signal,
    }).then((response) => {
      if (!response.ok) {
        throw Error("Could not create placeholder");
      }
      return response;
    }),
  );

  const { id, uploadingUrl } = await response.json();

  if (!id) {
    throw Error("No image ID in response");
  }

  if (!uploadingUrl) {
    throw Error("Invalid response when creating placeholder: upload URL missing");
  }

  return { id, uploadingUrl };
}

export type UploadData = {
  file: File;
} & Placeholder;

/** Upload file to placeholder created by Mimir */
export async function uploadToPlaceholder(
  { file, uploadingUrl }: UploadData,
  signal?: AbortSignal,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
): Promise<void> {
  // Comment back in to test error handling
  // if (Math.random() < 0.5) {
  //   throw Error("Random error when uploading");
  // }

  const filename = encodeURIComponent(file.name);

  await axios.put(uploadingUrl, file, {
    headers: {
      "Content-Type": file.type,
      "Content-Disposition": `attachment; filename="${filename}"`,
    },
    onUploadProgress,
    withCredentials: false,
    signal,
  });
}

export async function sendImageToKaleido(itemId: string): Promise<PublishedImage> {
  const response = await apiFetch(`${getApiUrl()}/images/${itemId}/publish`, {
    method: "POST",
  });

  if (!response.ok) {
    if (response.status === 415) {
      throw Error("Kaleido kan ikke håndtere dette bildeformatet");
    } else if (response.status === 413) {
      throw Error("Bildet er for stort for å laste opp til Kaleido");
    } else {
      throw Error("Kunne ikke publisere bildet til Kaleido");
    }
  }

  return response.json();
}
/** Delete image from Mimir */
export async function deleteImage(id: string): Promise<void> {
  const response = await apiFetch(`${getApiUrl()}/images/${id}`, { method: "DELETE" });

  const code = response.status;

  if (code !== 200) {
    throw Error("Kunne ikke slette bildet");
  }
}

export async function restoreVersion(id: string): Promise<string> {
  const response = await apiFetch(`${getApiUrl()}/images/${id}/promote`, { method: "POST" });

  return response.statusText;
}

export async function user(id: string): Promise<Mimir.User> {
  const response = await apiFetch(`${getApiUrl()}/user/${id}`, {
    method: "GET",
  });

  return response.json();
}

let currentUser: Mimir.User;

/** Ask backend for Mimir user corresponding to current logged-in user (based on auth token) */
export async function whoami(): Promise<Mimir.User> {
  try {
    const response = await apiFetch(`${getApiUrl()}/whoami`, { method: "GET" });

    if (!response.ok) {
      const body = await response.json();
      throw Error(
        "Kunne ikke hente brukerprofil fra Mimir" + (body?.message ? ": " + body.message : ""),
      );
    }

    currentUser = await response.json();
    return currentUser;
  } catch (error) {
    if (error instanceof TypeError) {
      throw Error("Fikk ikke kontakt med server");
    } else {
      throw error;
    }
  }
}

/** Get cached mimir user */
export function getCurrentUser(): Mimir.User {
  if (currentUser) {
    return currentUser;
  }
  throw Error("No current user available, has app been initialized properly?");
}
