<svelte:options immutable />

<script lang="ts">
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";

  import type { SearchHit } from "services/searchHit";
  import type { SearchQuery } from "state/queries";
  import { getCountFromQuery } from "state/queries/index";
  import { getErrorMessage } from "utils/fns";

  import ImageTable from "./ImageTable/ImageTable.svelte";
  import ImageTiles from "./ImageTiles/ImageTiles.svelte";
  import type { SearchHitHandler } from "./searchHitHandler";
  import { currentViewType } from "./viewType";

  export let query: SearchQuery<SearchHit>;
  export let handler: SearchHitHandler;
  export let onKeyDown: (event: KeyboardEvent) => void = () => {};
  export let searchHits: SearchHit[];

  export let createPreloader: (searchHit: SearchHit) => () => Promise<void> = () => () =>
    Promise.resolve();

  async function getMore() {
    await $query.fetchNextPage();
  }

  $: count = getCountFromQuery(query);

  let mounted = false;
  let rendered = false;

  onMount(() => {
    mounted = true;
    // If we have a cache with many search hits, it takes a long time to calculate the DOM changes.
    // This flag allows us to render _something_ first, and then load all the search hits into the DOM
    setTimeout(() => (rendered = true), 0);
  });
</script>

{#if mounted && !rendered}
  <div class="state-wrapper" in:fade={{ delay: 1000, duration: 400 }}>
    <div class="org-spinner"></div>
    <p>Laster</p>
  </div>
{:else if rendered && $query.status === "success" && $count > 0}
  {#if $currentViewType === "TILES"}
    <ImageTiles {searchHits} {getMore} {handler} {onKeyDown} {createPreloader} />
  {:else if $currentViewType === "TABLE"}
    <ImageTable {searchHits} {getMore} {handler} {onKeyDown} {createPreloader} />
  {/if}
{:else if $query.status === "error"}
  <div class="state-wrapper">
    <h3>Kunne ikke hente søketreff</h3>
    <p class="warning">{getErrorMessage($query.error)}</p>
  </div>
{:else if $query.status === "success" && $count === 0}
  <div class="state-wrapper">
    <h3>Ingen treff</h3>
    <p>Prøv å utvide søket.</p>
  </div>
{/if}
{#if $query.isFetchingNextPage}
  <div class="fetching-more" in:fade={{ delay: 200, duration: 100 }}>Henter bilder…</div>
{/if}

<style>
  .state-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    color: #888;
  }

  h3 {
    margin: var(--org-small);
  }

  .fetching-more {
    width: 100%;
    padding: var(--org-small);
    margin-bottom: var(--org-medium);
    text-align: center;
  }

  .org-spinner {
    margin-bottom: var(--org-medium);
    opacity: 0.8;
  }
</style>
