<svelte:options immutable />

<script lang="ts">
  import type { Image } from "bildebanken-model";
  import { getThumbnailUrl } from "state/thumbnails";
  import { getFileUploadStatus } from "state/upload";
  import { getBildebankenHost } from "../../config";
  import Progress from "../Common/Progress.svelte";
  import type { EditImageState } from "./editImageMachine";
  import { fieldLabels, requiredFieldsMissingInImage } from "./editImageMachine";
  import { getKaleidoDerivateUrl } from "services/kaleido";
  import th from "date-fns/locale/th";

  export let image: Partial<Image> & Pick<Image, "originalFileName">;
  export let selected: boolean;
  export let onSelect: () => void;
  export let state: EditImageState;

  $: missingFields = image.id ? requiredFieldsMissingInImage(state, image.id) : [];

  function onSelectionChange(this: HTMLInputElement, _: Event) {
    if (this.checked) {
      onSelect();
    }
  }

  const filename = image.originalFileName;

  // TODO: can be replaced with :has(:focus) when support is better (Firefox missing at time of writing)
  let focus = false;

  // we use filename for lookup because not all files have an ID
  const uploadStatus = getFileUploadStatus(filename);

  function getUploadError() {
    if ($uploadStatus?.state === "FAILED" && "error" in $uploadStatus) {
      return $uploadStatus.error || "Ukjent årsak";
    }
  }

  let err = false;

  const getEmptyImageUrl = () => {
    return new URL("/image-icon.svg", getBildebankenHost()).toString();
  };

  async function getThumbnailOrKaleidoDerivateUrl() {
    const thumbnailUrl = getThumbnailUrl(image);

    // Fallback to Kaleido derivate if thumbnail is missing
    if (!thumbnailUrl && image.metadata?.publicId) {
      return await getKaleidoDerivateUrl(image.metadata?.publicId, { width: 300, height: 300 });
    }

    return thumbnailUrl;
  }
</script>

<div
  class="tile"
  class:failed={$uploadStatus?.state === "FAILED" || missingFields.length > 0}
  class:focus
  class:selected
  data-testid="edit-preview"
>
  <div class="left-half">
    {#if err}
      <img class="error" alt="" src={getEmptyImageUrl()} />
    {:else}
      {#await getThumbnailOrKaleidoDerivateUrl() then thumbnail}
        <img class="" alt="" src={thumbnail} on:error={() => (err = true)} />
      {/await}
    {/if}
  </div>
  <div class="right-half">
    <div class="tile-description">
      {#if $uploadStatus?.state === "FAILED"}
        <div class="selectable-text">
          <h3>
            {filename}
          </h3>
          <div class="org-warning">
            Opplasting feilet: {getUploadError()}
          </div>
        </div>
      {:else}
        {#if state.matches("Editing.Single")}
          <label>
            <input
              type="radio"
              name="image-edited"
              checked={selected}
              on:change={onSelectionChange}
              on:focus={() => (focus = true)}
              on:blur={() => (focus = false)}
            />
            <div class="selectable-text">{image.metadata?.title}</div>
          </label>
        {:else}
          <h3>{image.metadata?.title}</h3>
        {/if}
        <div class="selectable-text">{image.metadata?.description || ""}</div>
      {/if}
    </div>
    {#if missingFields?.length > 0}
      <div class="org-warning">
        Felt mangler:
        <em>{missingFields.map((field) => fieldLabels[field]).join(", ")}</em>.
      </div>
    {/if}
    {#if $uploadStatus}
      <Progress
        status={$uploadStatus.state === "FAILED"
          ? "FAILED"
          : $uploadStatus.state === "SUCCESS"
          ? "SUCCESS"
          : "IN_PROGRESS"}
        value={$uploadStatus.progress}
        id={"file-upload-" + filename}
        srLabel={"Opplasting"}
      />
    {/if}
  </div>
</div>

<style>
  .tile {
    display: flex;
    flex-shrink: 0;
    border: solid 2px transparent;
    border-radius: var(--bb-standard-border-radius);
    transition: border 200ms;
    overflow: hidden;
    position: relative;
    color: var(--color-text-invert);
  }

  .tile:hover {
    border-color: var(--org-color-gray-500);
    cursor: pointer;
  }

  .tile.focus {
    border-color: var(--org-color-focus);
  }

  input[type="radio"] {
    cursor: pointer;
    display: block;
    position: absolute;
    appearance: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: solid 2px transparent;
    margin: 0;
    transition: border 200ms;
    border-radius: var(--bb-standard-border-radius);
    /* pointer-events: none; */
  }

  .selected,
  .tile.selected:hover {
    border-color: var(--org-color-primary);
  }

  .selected .right-half {
    background-color: var(--bb-selected-bg-color);
  }

  .failed,
  .tile.failed:hover {
    border-color: var(--org-color-warning);
    background-color: var(--color-surface-primary);
    cursor: unset;
    filter: none;
  }

  .right-half {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: var(--org-small);
    gap: var(--org-small);
    height: 100%;
    position: relative;
    background: var(--color-surface-secondary);
  }

  .left-half {
    width: 270px;
    height: 160px;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left-half img {
    height: 100%;
  }

  .left-half img.error {
    width: max(20px, calc(40px * var(--scale)));
    height: max(20px, calc(40px * var(--scale)));
  }

  .tile-description {
    color: var(--color-text-primary);
    flex: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    transition:
      background-color 200ms,
      filter 200ms;
  }

  .selectable-text {
    user-select: text;
  }

  .tile:hover .tile-description {
    filter: brightness(1.1);
  }

  .org-warning {
    width: fit-content;
    padding-right: 1em;
  }
</style>
