import OrigoDialog from "@nrk/origo-dialog";
import OrigoExpand from "@nrk/origo-expand";
import OrigoDropdown from "@nrk/origo-dropdown";
import OrigoProgress from "@nrk/origo-progress";
import OrigoTabs from "@nrk/origo-tabs";
import OrigoSuggest from "@nrk/origo-suggest";
import OrigoDatepicker from "@nrk/origo-datepicker";

window.customElements.define("bb-dialog", OrigoDialog);
window.customElements.define("bb-expand", OrigoExpand);
window.customElements.define("bb-dropdown", OrigoDropdown);
window.customElements.define("bb-datepicker", OrigoDatepicker);
window.customElements.define("bb-progress", OrigoProgress);
window.customElements.define("bb-suggest", OrigoSuggest);
window.customElements.define("bb-tabs", OrigoTabs);
