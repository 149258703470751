export type RequiredFieldKey = "description" | "altText" | "imageType";
export type HighlightedFieldKey = "credit";

export type EditOptions = {
  requiredFields?: RequiredFieldKey[];
  selectImageOnSave?: boolean;
  editOnlyMode?: boolean;
  highlightedFields?: HighlightedFieldKey[];
};

const uploadOptions: EditOptions = {};
let system: string | undefined;

export function setUploadOptions(options: EditOptions) {
  uploadOptions.requiredFields = options.requiredFields || [];
  uploadOptions.selectImageOnSave = options.selectImageOnSave;
  if (uploadOptions.selectImageOnSave === undefined) {
    uploadOptions.selectImageOnSave = true;
  }
}

//Hvis aktuelt å få highlightedFields til å fungere i initialView kan noe tilsvarende gjøres med den
export function setEditOnlyMode(editOnlyMode: boolean) {
  uploadOptions.editOnlyMode = editOnlyMode;
}

export function getEditOnlyMode() {
  return uploadOptions.editOnlyMode;
}

export function getRequiredFields(): RequiredFieldKey[] | undefined {
  return uploadOptions.requiredFields;
}

export function getSelectImageOnSave(): boolean | undefined {
  return uploadOptions.selectImageOnSave;
}

export function getSystem(): string | undefined {
  return system;
}

export function setSystem(systemName: string) {
  system = systemName;
}
