<script lang="ts">
  import { createQuery } from "@tanstack/svelte-query";
  import Folder from "components/Folders/Folder.svelte";
  import type { Folder as FolderType } from "services/folders/types";
  import { orgClose, orgExternalLink } from "@nrk/origo";
  import nb from "date-fns/locale/nb";
  import { isoDateRe, rootFolderIds, setFolderFilter, type Path } from "./folders";
  import { getEnvironmentFromHostname } from "utils/fns";
  import { format } from "date-fns";
  import { getBildebankenHost } from "config";
  import { getFolderContent } from "services/folders/api";
  import { currentFolder } from "state/page";
  import { isEqual, sortBy } from "lodash-es";
  import EmptyPeriod from "./EmptyPeriod.svelte";

  export let folder: FolderType = {
    name: "Samarbeidsmapper",
    id: ["prod", "beta"].includes(getEnvironmentFromHostname(getBildebankenHost().hostname))
      ? rootFolderIds.prod.album
      : rootFolderIds.stage.album,
    hasSubFolder: true,
  };

  export let folderDate: Date | undefined = undefined;

  export let current: { folder?: FolderType; parent?: FolderType } = $currentFolder || {};

  export let folderFilter: (folder: FolderType) => boolean = () => true;

  $: filteredChildren = folder.children?.filter(folderFilter) || [];

  const query = createQuery({
    queryKey: ["folders", folder.id],
    refetchInterval: 10000,
    queryFn: async (context) => {
      const folderId = context.queryKey.at(1);
      if (folderId) {
        const content = await getFolderContent(folderId);
        return content;
      }
    },
    onSuccess: (data) => {
      if (data) {
        const childrenChanged = !isEqual(
          sortBy(data.folders.map((f) => f.id)),
          sortBy(folder.children?.map((f) => f.id)),
        );
        const imagesChanged = !isEqual(
          sortBy(data.images.map((i) => i.id)),
          sortBy(folder.images?.map((i) => i.id)),
        );
        if (childrenChanged) {
          folder.children = data.folders.sort((a, b) => a.name?.localeCompare(b.name));
        }
        if (imagesChanged) {
          folder.images = data.images;
        }
      }
    },
    refetchOnWindowFocus: false,
  });

  export let expandLeafFolder = false;

  const closeCurrentFolder = () => {
    $currentFolder = {};
    current = {};
  };

  const onSelect = (event: CustomEvent<any>) => {
    const { folder, parent, expanded } = event.detail;
    if (expanded && !expandLeafFolder) {
      current = { folder, parent };
      $currentFolder = current;
    }
    current = { folder: event.detail.folder, parent: event.detail.parent };
    $currentFolder = current;
  };

  const onCreatedFolders = () => $query.refetch();

  let creatingFolders = false;
</script>

<div class="wrapper">
  {#if current.folder}
    <div class="header">
      <div>
        <button class="org-button icon" on:click={closeCurrentFolder}>
          {@html orgClose}
        </button>
        <strong>
          {current.folder.name}
          {(current.parent?.name?.match(isoDateRe) &&
            format(new Date(current.parent.name), "d. MMMM", { locale: nb })) ||
            ""}
        </strong>
      </div>
      <a class="org-button icon" href="?folderId={current?.folder?.id || ''}">
        {@html orgExternalLink}
      </a>
    </div>
    <Folder folder={current.folder} contentOnly={true} />
  {:else if filteredChildren && $query.isSuccess}
    {#if filteredChildren?.length === 0 || creatingFolders}
      <EmptyPeriod
        {folderDate}
        bind:unsavedChanges={creatingFolders}
        on:createdFolders={onCreatedFolders}
      />
    {:else}
      {#each filteredChildren as child (child.id)}
        <Folder folder={child} bind:current {expandLeafFolder} on:select={onSelect} />
      {/each}
    {/if}
  {/if}
</div>

<style>
  .header {
    display: flex;
    justify-content: space-between;
  }

  .icon {
    font-size: 0.8rem;
  }
</style>
