<script lang="ts">
  import { pageState, returnToSearchPage } from "state/page";
  import BottomActionBar from "./BottomActionBar.svelte";
  import Detail from "./Detail/index.svelte";
  import Edit from "./Editor/Edit.svelte";
  import LandingPage from "./LandingPage/index.svelte";
  import SearchBar from "./SearchPage/SearchBar.svelte";
  import SearchResults from "./SearchPage/SearchResults.svelte";
  import TopBar from "./TopBar.svelte";
  import Upload from "./Upload/Upload.svelte";
  import { onMount } from "svelte";
  import { productFruitsInstance } from "services/productFruitsService";
  import SideMenu from "./SideMenu.svelte";

  $: hideNavigation = $pageState.type === "LANDING_PAGE";

  onMount(() => {
    $productFruitsInstance?.api.events.track("standalone-loaded");
  });
</script>

<TopBar {hideNavigation} />

{#if $pageState.type === "LANDING_PAGE"}
  <main>
    <LandingPage />
  </main>
{:else if $pageState.type === "UPLOAD"}
  <main>
    <Upload />
  </main>
{:else if $pageState.type === "EDIT"}
  <main>
    <Edit itemIds={$pageState.itemIds} onCancel={returnToSearchPage} onDone={returnToSearchPage} />
  </main>
{:else if $pageState.type === "DETAIL"}
  <main>
    <Detail itemId={$pageState.itemId} itemSource={$pageState.itemSource} />
  </main>
{:else}
  <main>
    <SearchBar />
    <!--height calc in css below can be read as (fullheight - header - searchbar) -->
    <div style=" display:flex; flex-direction: row; height:calc(100vh - 50px - 80px);">
      <SideMenu />
      <SearchResults />
    </div>
  </main>
  <BottomActionBar />
{/if}

<style>
  :root {
    background: black;
    overflow-y: hidden;
  }
  main {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--color-surface-primary);
    flex: 1;
    min-height: 0; /* to move scrolling to images-container instead of whole page */
    overflow: auto; /* gives proper scrolling for detail page (tall images for instance) */
  }
</style>
