<script lang="ts">
  import { searchFilter } from "state/page";
  import DateFilter from "./DateFilter.svelte";
  import PhotographerFilter from "./PhotographerFilter.svelte";
  import RightsFilter from "./RightsFilter.svelte";
  import SourceSystemFilter from "./SourceSystemFilter.svelte";
</script>

<div id="filter-container" class="filter-container">
  <div class:active-filter={$searchFilter.rightsMarker === "Free"} class="filter-element">
    <RightsFilter />
  </div>
  <div class:active-filter={$searchFilter.photographer} class="filter-element">
    <PhotographerFilter />
  </div>
  <div class:active-filter={$searchFilter.date} class="filter-element">
    <DateFilter />
  </div>
  <div class:active-filter={$searchFilter.sourceSystems?.length} class="filter-element">
    <SourceSystemFilter />
  </div>
</div>

<style>
  .filter-container {
    margin: 0 0 var(--org-xsmall);
    padding: 0;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    gap: var(--org-xsmall);
    position: relative;
    transition:
      opacity 0.2s,
      height 0.3s;
  }

  .filter-element {
    padding: 0 var(--org-xsmall) var(--org-xsmall);
    position: relative;
  }

  .filter-element :global(bb-expand) {
    padding: var(--org-small);
  }

  .active-filter {
    background: var(--org-color-shade-1);
  }
</style>
