<script lang="ts">
  import { toPartialImage, type Image, type Mimir } from "bildebanken-model";
  import { createPublishImageMutation } from "state/queries/mimir";

  export let item: Image | Mimir.SearchResultItem;
  const image = toPartialImage(item);
  const publishMutation = createPublishImageMutation(item.id);
</script>

{#if $publishMutation.isError}
  <div class="org-warning">{$publishMutation.error}</div>
{/if}
{#if !image?.metadata?.publicId}
  <button
    class="org-button org-button--secondary"
    on:click={() => $publishMutation.mutate()}
    aria-busy={$publishMutation.isLoading}
    title="For å kunne bruke bildet i andre systemer"
  >
    Send til Kaleido
  </button>
{/if}
