<script lang="ts">
  import { uploads } from "state/upload";
  import { upload } from "./upload";

  let uploadButtonFocus = false;
  $: uploadsInProgress = $uploads.state === "IN_PROGRESS";

  async function onChange(this: HTMLInputElement) {
    if (this.files) {
      await upload(this.files);
    }

    /* Reset value to enable the same
       file(s) to be selected again.  */
    this.files = null;
    this.value = "";
  }
</script>

<label class:focus={uploadButtonFocus} aria-busy={uploadsInProgress}>
  <slot />
  <input
    multiple
    type="file"
    class="org-sr"
    disabled={uploadsInProgress}
    on:change={onChange}
    on:focus={() => (uploadButtonFocus = true)}
    on:blur={() => (uploadButtonFocus = false)}
  />
</label>

<style>
  label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: unset;
    font-weight: unset;

    /* Make label grow in parent to extend click area */
    flex: 1;
  }

  label.focus {
    box-shadow: var(--org-shadow-focus);
  }
</style>
