import { writable } from "svelte/store";

type GlassPaneState =
  | {
      state: "HIDDEN";
    }
  | {
      state: "VISIBLE" | "SHOW_ERROR";
      message: string;
      details?: string;
    };

export const glassPaneState = writable<GlassPaneState>({ state: "HIDDEN" });
