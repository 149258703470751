import { derived, get, writable } from "svelte/store";

import { currentViewType } from "./viewType";

type ImageSize = {
  tiles: number;
  table: number;
};

const imageSizeKey = "bildebanken-image-size";
const rawSizes = localStorage.getItem(imageSizeKey);
const originalSize = rawSizes ? (JSON.parse(rawSizes) as ImageSize) : { tiles: 1, table: 1 };
const _imageSize = writable(originalSize);

export function setImageSize(size: number) {
  _imageSize.update((value) => {
    let newValue: ImageSize;
    const viewType = get(currentViewType);
    if (viewType === "TILES") {
      newValue = { ...value, tiles: size };
    } else {
      newValue = { ...value, table: size };
    }

    localStorage.setItem(imageSizeKey, JSON.stringify(value));
    return newValue;
  });
}
export const tilesImageSize = derived(_imageSize, (value) => value.tiles);

export const tableImageSize = derived(_imageSize, (value) => value.table);

export const imageSize = derived([_imageSize, currentViewType], ([size, viewType]) => {
  if (viewType === "TILES") {
    return size.tiles;
  } else {
    return size.table;
  }
});
