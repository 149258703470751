<script lang="ts">
  import { orgClose, orgSpeak } from "@nrk/origo";
  import { productFruitsInstance } from "services/productFruitsService";

  let hidden = false;

  const hideButton = () => {
    hidden = true;
  };

  const openFeedbackModal = () => {
    hidden = true;
    $productFruitsInstance?.api.feedback.showModal({ bottom: "1.5rem", right: "1.5rem" }, () => {
      hidden = false;
    });
  };
</script>

<div class="wrapper" class:hidden>
  <button class="org-button" on:click|preventDefault={openFeedbackModal}>
    {@html orgSpeak} Gi tilbakemelding
  </button>
  <button class="org-button close" on:click|preventDefault={hideButton}>
    {@html orgClose}
    <span class="org-sr">Lukk</span>
  </button>
</div>

<style>
  .wrapper {
    color: var(--color-text-secondary);
    --blur-radius: 5px;
    backdrop-filter: blur(var(--blur-radius));
    -webkit-backdrop-filter: blur(var(--blur-radius));
    background-color: rgba(var(--color-surface-primary-rgb), 0.6);
    border-radius: var(--org-xsmall);
  }

  .wrapper:hover {
    color: unset;
  }

  button {
    margin: unset;
    min-width: unset;
  }

  .hidden {
    display: none;
  }

  .close {
    font-size: 0.75em;
    display: none;
  }

  .wrapper:hover .close {
    display: unset;
  }
</style>
