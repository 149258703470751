import { wait } from "./fns";

export async function withRetry<T>(
  task: () => Promise<T>,
  retries = 2,
  initialDelay = 1000,
): Promise<T> {
  try {
    return await task();
  } catch (err: unknown) {
    if (retries > 0) {
      console.warn(`Call failed with error, will retry after ${initialDelay}ms:`, err);
      await wait(initialDelay);
      return withRetry(task, retries - 1, initialDelay * 2);
    }
    throw err;
  }
}
