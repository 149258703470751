<script lang="ts">
  export let value: number;
  export let srLabel: string;
  export let id: string;
  export let status: "SUCCESS" | "FAILED" | "IN_PROGRESS";

  $: actualValue = Number.isFinite(value) ? value : 0;
</script>

<div class:success={status === "SUCCESS"} class:failure={status === "FAILED"}>
  <label class="org-sr" for={id}>{srLabel}</label>
  <progress value={actualValue} {id} />
</div>

<style>
  progress {
    display: block;
    height: 3px;
    width: 100%;
    border: none;
    background-color: transparent;
  }

  /*
    The below uses non-standard pseudo elements,
    the progress bar doesn't look terrible when they're not there either
  */
  ::-webkit-progress-bar {
    background-color: transparent;
  }

  ::-webkit-progress-value,
  ::-moz-progress-bar {
    accent-color: var(--org-color-primary);
    background-color: var(--org-color-primary);
  }

  .success ::-webkit-progress-value,
  .success ::-moz-progress-bar {
    background-color: var(--org-color-success);
  }

  .failure ::-webkit-progress-value,
  .failure ::-moz-progress-bar {
    background-color: var(--org-color-error);
  }

  /* Safari */
  progress {
    accent-color: var(--org-color-primary);
    appearance: none;
  }

  .success progress {
    accent-color: var(--org-color-success);
  }

  .failure progress {
    accent-color: var(--org-color-error);
  }
</style>
