import type { Configuration } from "@azure/msal-browser";
import { LogLevel } from "@azure/msal-browser";

const AZURE_BILDEBANKEN_API_SCOPE = "https://bildebanken.nrk.no/api";
export const SCOPES = [AZURE_BILDEBANKEN_API_SCOPE];

const AZURE_NRK_TENANT_ID = "9d2ac018-e843-4e14-9e2b-4e0ddac75450";
const AZURE_BILDEBANKEN_APP_ID = "362c9aeb-b5da-4ab5-b011-7c1dcb5de5f3";

export const msalConfig: Configuration = {
  auth: {
    clientId: AZURE_BILDEBANKEN_APP_ID,
    authority: `https://login.microsoftonline.com/${AZURE_NRK_TENANT_ID}/`,
    redirectUri: `${window.location.origin}/auth/response`,
    postLogoutRedirectUri: `${window.location.origin}/auth/signout`,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }

        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            //console.info(message);
            return;
          case LogLevel.Verbose:
            //console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};
