import { isEqual, omit } from "lodash-es";

import { userBelongsToStillsGroup } from "auth/auth";
import type { Image, Mimir } from "bildebanken-model";
import { getCurrentUser } from "services/api";
import { getBildebankenGroupId, getStillsGroupId } from "../../config";

export type VisibilityKey = "everyone" | "stills" | "me";
type VisibilityOption = { label: string; value: Mimir.AssetVisibility };
const getAllVisibilityOptions = (): Record<VisibilityKey, VisibilityOption> => ({
  everyone: {
    label: "Synlig for alle",
    value: {
      groups: [getBildebankenGroupId()],
      level: "selected_groups",
    },
  },
  me: {
    label: "Kun synlig for meg",
    value: {
      level: "owner",
      users: [getCurrentUser().id],
    },
  },
  stills: {
    label: "Kun synlig for Stills-brukere",
    value: {
      groups: [getStillsGroupId()],
      level: "selected_groups",
    },
  },
});

export const getDefaultVisibility = (): Mimir.AssetVisibility =>
  getAllVisibilityOptions().everyone.value;

export function getImageVisibility(image: Image | Mimir.SearchResultItem): VisibilityKey {
  if (!image.visibleTo || !image.visibleTo.level) {
    return "everyone";
  }

  if (image.visibleTo.level === "selected_groups") {
    if (image.visibleTo.groups?.includes(getBildebankenGroupId())) {
      return "everyone";
    }

    if (isEqual(image.visibleTo.groups, [getStillsGroupId()])) {
      return "stills";
    }
  }

  if (image.visibleTo.level === "owner") {
    return "me";
  }
  return "everyone";
}

export function getVisibilityOptions(): Record<"everyone" | "me", VisibilityOption> & {
  stills?: VisibilityOption;
} {
  if (userBelongsToStillsGroup()) {
    return getAllVisibilityOptions();
  }

  return omit(getAllVisibilityOptions(), "stills");
}
