<script lang="ts">
  import { orgClose } from "@nrk/origo";
  export let confirmText;
  export let headerText;
  export let onConfirm: () => void;
</script>

<div class="wrapper">
  <header class="org-bar org-bar--primary">
    <h1>{headerText}</h1>
    <button class="org-button close-button" data-for="close">
      {@html orgClose}
      <span class="org-sr">Lukk dialogen</span>
    </button>
  </header>
  <div class="message">
    <slot />
  </div>
  <div class="dialog-toolbar">
    <button class="org-button org-button--secondary" data-for="close"> Avbryt </button>
    <button
      class="org-button org-button--primary"
      data-testid="delete-image-confirm"
      data-for="close"
      on:click={() => onConfirm()}
    >
      {confirmText}
    </button>
  </div>
</div>

<style>
  .wrapper {
    min-height: 200px;
    max-height: 60vh;
    display: flex;
    flex-direction: column;
    gap: var(--org-small);
  }

  header {
    flex-shrink: 0;
  }

  .message {
    flex: 1;
    overflow-y: auto;
    padding: 0 var(--org-medium);
  }

  .dialog-toolbar {
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    padding: var(--org-small);
    gap: var(--org-xsmall);
  }

  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
  }
</style>
