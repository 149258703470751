<script lang="ts">
  import { orgCheck, orgCopy } from "@nrk/origo";

  export let text: string | undefined = undefined;
  export let title = "Kopier";
  export let onlyHover = false;

  let contentWrapperElement: HTMLElement;
  let done = false;

  const rootStyle = `--inactive-color: #949494;`; // Contrat ratio 4:52 to (--var-org-color-gray-700)
</script>

<div class="wrapper" style={rootStyle}>
  <div class="content-wrapper" bind:this={contentWrapperElement}><slot /></div>
  <button
    class="narrow"
    class:hidden={onlyHover}
    {title}
    on:click={() => {
      const toCopy = text || contentWrapperElement?.innerText;
      if (toCopy !== undefined) {
        navigator.clipboard.writeText(toCopy).then(() => {
          done = true;
          setTimeout(() => {
            done = false;
          }, 1000);
        });
      }
    }}>{@html done ? orgCheck : orgCopy}</button
  >
</div>

<style>
  .wrapper {
    display: inline-block;
  }

  button.narrow {
    opacity: 0.4;
    visibility: visible;
  }

  button.narrow.hidden {
    visibility: hidden;
  }

  .wrapper:hover button.narrow {
    opacity: 1;
    visibility: visible;
  }

  .content-wrapper {
    display: inline-block;
  }

  button.narrow {
    transition: opacity 0.25s;
    padding: 5px;
    margin: 0 5px;
    border: 0;
    color: inherit;
    background: none;
    cursor: pointer;
    border-radius: 4px;
    width: 25px;
    height: 25px;
  }

  button.narrow:hover {
    background-color: var(--org-color-shade-1, hsla(210, 15%, 50%, 0.2));
    transform: translateY(-1px);
  }

  button.narrow :global(svg) {
    width: 100%;
    height: 100%;
  }
</style>
