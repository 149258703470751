<script lang="ts">
  import { getBildebankenHost } from "config";
  import type { NtbSearchHit } from "services/searchHit";

  export let image: NtbSearchHit;
  export let mode: "thumbnail" | "preview" = "thumbnail";


  function getImageUrl(searchHit?: NtbSearchHit) {
    if (!searchHit) {
      return new URL("/image-icon.svg", getBildebankenHost()).toString();
    }
    return mode === "thumbnail" ? searchHit?.thumbnailUrl : searchHit?.previewUrl;
  }

  let src = getImageUrl(image);

</script>

{#if src}
  <img {src} alt="" loading="lazy" />
{/if}

<style>
  img {
    overflow: hidden;
    object-fit: contain;
    flex-grow: 1;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
</style>
