import { intersection, pull, uniq } from "lodash-es";
import { assign, createMachine, interpret, type StateFrom } from "xstate";

type ItemClickedEvent = {
  type: "ITEM_CLICKED";
  itemId: string;
  event: { shiftKey: boolean; ctrlKey: boolean; metaKey: boolean };
};

type UpdateItemsEvent = { type: "UPDATE_ITEMS"; itemIds: string[] };
type CheckboxEvent = {
  type: "TOGGLE_CHECKBOX";
  itemId: string;
  checked: boolean;
};

type ExtendSelectionEvent = {
  type: "EXTEND_SELECTION";
  itemIds: string[];
};

type RemoveFromSelectionEvent = {
  type: "REMOVE_FROM_SELECTION";
  itemIds: string[];
};

type SelectionEvent =
  | ItemClickedEvent
  | UpdateItemsEvent
  | CheckboxEvent
  | ExtendSelectionEvent
  | RemoveFromSelectionEvent
  | { type: "SELECT_ITEMS"; itemIds: string[] }
  | { type: "UNSELECT_ALL_ITEMS" };

type SelectionContext = {
  allIds: string[];
  selectedIds: string[];
};

type HasId = {
  id: string;
};

export const numberOfSelectedItems = (state: SelectionState) => state.context.selectedIds.length;

export const isItemSelected = (id: string) => (state: SelectionState) =>
  state.context.selectedIds.includes(id);

export const getSelectedIds = (state: SelectionState): string[] => state.context.selectedIds;

export function getSelectedItems<T extends HasId>(selectedIds: string[], allItems: T[]): T[] {
  return allItems.filter((item) => selectedIds.includes(item.id));
}

function clickedItemSelected(context: SelectionContext, event: ItemClickedEvent) {
  return context.selectedIds.includes(event.itemId);
}

function ctrlOrMetaPressed(event: ItemClickedEvent) {
  return event.event.ctrlKey || event.event.metaKey;
}

function shiftPressed(event: ItemClickedEvent) {
  return event.event.shiftKey;
}

const updateItems = assign<SelectionContext, UpdateItemsEvent>({
  allIds: (_, { itemIds }) => itemIds,
  selectedIds: ({ selectedIds }, event) => intersection(selectedIds, event.itemIds),
});

const selectItem = assign<SelectionContext, ItemClickedEvent>({
  selectedIds: (_, event) => [event.itemId],
});

const extendSelection = assign<SelectionContext, ExtendSelectionEvent>({
  selectedIds: ({ selectedIds }, { itemIds }) => uniq([...selectedIds, ...itemIds]),
});

const removeFromSelection = assign<SelectionContext, RemoveFromSelectionEvent>({
  selectedIds: ({ selectedIds }, { itemIds }) => pull([...selectedIds], ...itemIds),
});

const toggleSelectItem = assign<SelectionContext, ItemClickedEvent>({
  selectedIds: (context, event) => {
    if (clickedItemSelected(context, event)) {
      return context.selectedIds.filter((id) => id !== event.itemId);
    }
    return [...context.selectedIds, event.itemId];
  },
});

const selectRange = assign<SelectionContext, ItemClickedEvent>({
  selectedIds: (context, event) => {
    const numberSelected = context.selectedIds.length;
    if (numberSelected === 0) {
      return [event.itemId];
    }

    const lastSelectedItemId = context.selectedIds[numberSelected - 1];
    const indexOfLastSelected = context.allIds.findIndex((id) => id === lastSelectedItemId);
    const indexOfClicked = context.allIds.findIndex((id) => id === event.itemId);

    const selectedIds =
      indexOfClicked > indexOfLastSelected
        ? context.allIds.slice(indexOfLastSelected + 1, indexOfClicked + 1)
        : context.allIds.slice(indexOfClicked, indexOfLastSelected);

    return uniq(context.selectedIds.concat(selectedIds));
  },
});

type SelectionState = StateFrom<typeof selectionMachine>;

const selectionMachine = createMachine<SelectionContext, SelectionEvent>({
  id: "selection",
  initial: "NoItemsSelected",
  predictableActionArguments: true,
  schema: {
    context: {} as SelectionContext,
    events: {} as SelectionEvent,
  },
  context: {
    allIds: [],
    selectedIds: [],
  },
  on: {
    UPDATE_ITEMS: {
      actions: updateItems,
      target: "Determining",
    },
    UNSELECT_ALL_ITEMS: {
      target: "NoItemsSelected",
    },
    TOGGLE_CHECKBOX: {
      actions: assign({
        selectedIds: ({ selectedIds }, event) => {
          if (event.checked) {
            return uniq([...selectedIds, event.itemId]);
          }
          return selectedIds.filter((id) => id !== event.itemId);
        },
      }),
      target: "Determining",
    },
    SELECT_ITEMS: {
      actions: assign({
        selectedIds: (_, event) => event.itemIds,
      }),
      target: "ItemsSelected",
    },
    EXTEND_SELECTION: {
      actions: extendSelection,
      target: "ItemsSelected",
    },
  },
  states: {
    Determining: {
      always: [
        { cond: (context) => context.selectedIds?.length === 0, target: "NoItemsSelected" },
        { target: "ItemsSelected" },
      ],
    },
    ItemsSelected: {
      on: {
        ITEM_CLICKED: [
          {
            cond: (context, event) => ctrlOrMetaPressed(event) && context.selectedIds.length > 1,
            actions: toggleSelectItem,
          },
          {
            cond: (context, event) =>
              ctrlOrMetaPressed(event) && !clickedItemSelected(context, event),
            actions: toggleSelectItem,
          },
          {
            cond: (context, event) =>
              clickedItemSelected(context, event) && context.selectedIds.length === 1,
            target: "NoItemsSelected",
          },
          {
            cond: (context, event) => shiftPressed(event) && !clickedItemSelected(context, event),
            actions: selectRange,
          },
          {
            actions: selectItem,
          },
        ],
        REMOVE_FROM_SELECTION: {
          actions: removeFromSelection,
          target: "Determining",
        },
      },
    },
    NoItemsSelected: {
      entry: [assign({ selectedIds: [] })],
      on: {
        ITEM_CLICKED: {
          actions: selectItem,
          target: "ItemsSelected",
        },
      },
    },
  },
});

const service = interpret(selectionMachine.withContext({ selectedIds: [], allIds: [] }));
service.start();

export function resetSelection(): void {
  service.send({ type: "SELECT_ITEMS", itemIds: [] });
}

export default service;
