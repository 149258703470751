<script lang="ts">
  import { getBildebankenHost, getKaleidoConfig } from "config";
  import type { KaleidoSearchHit } from "services/searchHit";
  import { getKaleidoDerivateUrl } from "../../services/kaleido";

  export let image: KaleidoSearchHit;
  export let mode: "thumbnail" | "preview" = "thumbnail";

  const kaleidoConfig = getKaleidoConfig();

  function getImageUrl(searchHit?: KaleidoSearchHit) {
    if (!kaleidoConfig || !searchHit) {
      return new URL("/image-icon.svg", getBildebankenHost()).toString();
    }

    return mode === "thumbnail" ? searchHit?.thumbnailUrl : undefined;
  }

  let src = getImageUrl(image);

  if (mode === "preview") {
    getKaleidoDerivateUrl(image.id, { width: 1000 }).then((url) => (src = url));
  }
</script>

{#if src}
  <img {src} alt="" loading="lazy" />
{/if}

<style>
  img {
    overflow: hidden;
    object-fit: contain;
    flex-grow: 1;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
</style>
