import { toLower } from "lodash-es";
import { getKaleidoDerivateUrl } from "services/kaleido";
import type { previewImageBoundries } from "services/pluginMessages";
import type { PreviewInfo, SelectedImage } from "../imageTypes";

let preferredMaxHeight: number | undefined, preferredMaxWidth: number | undefined;

export type ImageBoundriesToKaleido = LandscapeImage | PortraitImage;
type LandscapeImage = { width: number };
type PortraitImage = { height: number };

export function setPreviewImageBoundries(boundries: previewImageBoundries) {
  preferredMaxHeight = boundries.maxHeight;
  preferredMaxWidth = boundries.maxWidth;
}

export async function appendKaleidoDerivateUrlToImage(image: SelectedImage) {
  if (image.publications.length > 0 && toLower(image.publications[0].system) === "kaleido") {
    image.preview = await getKaleidoDerivateURLPromise(
      image.publications[0].id,
      image.technicalMetadata.height,
      image.technicalMetadata.width,
    );
  }
}

async function getKaleidoDerivateURLPromise(imageId, imageHeigh, imageWidth): Promise<PreviewInfo> {
  const size = calculateDerivateSize(imageHeigh, imageWidth);
  const previewUrl = await getKaleidoDerivateUrl(imageId, size);
  if (!previewUrl) {
    throw Error("Kunne ikke hente forhåndsvisning. Prøv igjen");
  }
  const preview: PreviewInfo = { url: previewUrl, ...size };
  return preview;
}

function calculateDerivateSize(imageHeight: number, imageWidth: number): ImageBoundriesToKaleido {
  if (!preferredMaxHeight && !preferredMaxWidth) {
    return { height: imageHeight };
  }

  const widthFraction = imageWidth / (preferredMaxWidth || imageWidth);
  const heightFraction = imageHeight / (preferredMaxHeight || imageHeight);
  if (heightFraction <= 1 && widthFraction <= 1) {
    return { height: imageHeight };
  } else {
    if (widthFraction > heightFraction) {
      return {
        width: preferredMaxWidth ? Math.min(imageWidth, preferredMaxWidth) : imageWidth,
      };
    } else {
      return {
        height: preferredMaxHeight ? Math.min(imageHeight, preferredMaxHeight) : imageHeight,
      };
    }
  }
}
