<script lang="ts">
  import { useMachine } from "@xstate/svelte";

  import { loginMachine } from "auth/auth";
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { getAuthenticationFlow } from "../../config";
  import Status from "./Status.svelte";

  const { state, send } = useMachine(
    loginMachine.withContext({
      flow: getAuthenticationFlow(),
    }),
  );

  let mounted = false;
  onMount(() => (mounted = true));
</script>

{#if $state.matches("LoggedIn")}
  <slot />
{:else}
  <Status>
    {#if $state.matches("CheckLogin") || $state.matches("GetUserProfile")}
      {#if mounted}
        <div class="status" in:fade={{ duration: 500, delay: 500 }}>
          <p>Autentiserer…</p>
        </div>
      {/if}
    {:else if $state.matches("Popup") || $state.matches("RedirectPrompt")}
      <h2>Bildebanken</h2>
      <p>For å vise dine bilder må vi vite hvem du er.</p>
      <button
        class="org-button org-button--primary sign-in"
        on:click|preventDefault={() => send("SHOW_POPUP")}
        aria-busy={$state.matches("Popup.Active")}
        aria-disabled={$state.matches("Popup.Active")}
      >
        Logg på
      </button>
    {:else if $state.matches("CallbackFailed")}
      <p>Kunne ikke hente access-token. Er plugin feilkonfigurert?</p>
      <button
        class="org-button org-button--primary sign-in"
        on:click|preventDefault={() => send("TRY_POPUP")}
      >
        Logg på
      </button>
    {:else if $state.matches("CouldNotGetUserProfile")}
      <p>Kunne ikke hente brukerprofilen</p>
      <button
        class="org-button org-button--primary sign-in"
        on:click|preventDefault={() => send("TRY_POPUP")}
      >
        Logg på
      </button>
    {:else if mounted}
      <div in:fade={{ duration: 500, delay: 1000 }}>
        <h2>Ukjent feil</h2>
        <p>
          Kunne ikke starte starte bildevelger. Prøv på nytt eller ta kontakt hvis problemet består.
        </p>
        <p>Tilstand: {$state.value}</p>
      </div>
    {/if}
  </Status>
{/if}

<style>
  h2 {
    font-size: 1.1rem;
  }
  .sign-in {
    padding: 4px 10px;
  }
</style>
