export type BackgroundImage = {
  id: string;
  byline: string;
  url: string;
  position?: number;
};

export const featuredImages = [
  {
    id: "a2bcd759-4142-4aad-a684-d46b77924a09",
    byline: "Snorre Tønset / NRK",
    url: "https://gfx.nrk.no/B37ZgLutTdHMw1oaOZuSRwz7siDFKzojaXD_W6f4AQ3w",
  },

  {
    id: "D8chI01Lto65uKLAjs1KGg",
    byline: "Njord Røv / NRK",
    url: "https://gfx.nrk.no/PckUNWvcCjlSlA4xOgncnwwT7vus9YA_3m6xZAxttaZg",
    // url: "https://gfx.nrk.no/D8chI01Lto65uKLAjs1KGgX_7S8q1NgwwTHmC2QTyy2A",
  },
  {
    id: "59yNfUsh_e6lyTPFkmEoRA",
    byline: "Bernt Olsen / NRK",
    url: "https://gfx.nrk.no/59yNfUsh_e6lyTPFkmEoRAs-T90yHhXcaCuHCjDEgyiw",
  },
  {
    id: "7K7YYxK8PZIow9hTJElDEQ",
    byline: "Synnøve Sundby Fallmyr / NRK",
    url: "https://gfx.nrk.no/7K7YYxK8PZIow9hTJElDEQI--19bFxqpjWXzDnfT7exw",
  },
  {
    id: "0Sc6T9bpX0n-XsijykCFxw",
    byline: "Øyvind Sandnes / NRK",
    url: "https://gfx.nrk.no/0Sc6T9bpX0n-XsijykCFxwcp61NRNyapVfUr0Ta4kwRQ",
  },
  {
    id: "6Lc-6I322FbCSE8gWrwzeQ",
    byline: "Josef Benoni Ness Tveit / NRK",
    url: "https://gfx.nrk.no/6Lc-6I322FbCSE8gWrwzeQl6ggosq21X38_8Zm19neAg",
  },
  {
    id: "kOzBcybpKkpjTPfihPi3hA",
    byline: "André Fagernæs-Håker / NRK",
    url: "https://gfx.nrk.no/kOzBcybpKkpjTPfihPi3hADAss1tW6o6XV3qqln98opQ",
  },
  // {
  //   id: "944b4ca8-6e09-40f0-bdae-3845e640f7cb", // Granitt-fasade
  //   byline: "Lars Petter Pettersen / D2 Dagen",
  //   url: "https://gfx.nrk.no/ZdIz4vQLBm9ZlXgfb9ZlkQz6qqjH7XQiS1RGcPDq74IQ",
  //   position: 0.15,
  // },
  // {
  //   id: "94d43c1a-3e70-4e1c-97b9-98b0d2058801", // Møterom 2
  //   byline: "Lars Petter Pettersen / D2 Dagen",
  //   url: "https://gfx.nrk.no/27ZwcgYulvuKVax2bo6zoAt5YRMkNRD1M6HqK5y9kbfQ",
  //   position: 0.44,
  // },
  // {
  //   id: "30f8482d-3768-4b8e-8a99-0bbaff6d02bf", // B-fløy
  //   byline: "Lars Petter Pettersen / D2 Dagen",
  //   url: "https://gfx.nrk.no/xt-Nx543YEzg6Ywkwm3Yqw2m1uxdls6c3XITqn1e2urA",
  //   position: 0.3,
  // },
  // {
  //   id: "eed6a301-ddb4-49a0-844d-13cdf2ee30b1", // Funksjonelt hus
  //   byline: "Lars Petter Pettersen / D2 Dagen",
  //   url: "https://gfx.nrk.no/Ak0PK8_DfpXgw5zZ8iB6IgsVzlgQCU9WiHaYBOx5bD3A",
  //   position: 0.12,
  // },
];
