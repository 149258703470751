import * as msal from "@azure/msal-browser";
import {
  getAuthenticationFlow,
  getPluginHostAccount,
  getStillsGroupId,
  getTokenUsingCallback,
  type AuthenticationFlow,
  type UserAccount,
} from "config";
import { isPageState, pageState, pushPage } from "state/page";
import { get } from "svelte/store";
import { createMachine } from "xstate";
import { whoami } from "../services/api";
import { SCOPES, msalConfig } from "./config";

export type LoginRequest = { sid?: string; scopes: string[]; state: string };

const msalInstance = new msal.PublicClientApplication(msalConfig);

const authFlow = getAuthenticationFlow();

function getLoginRequest(): LoginRequest {
  return {
    scopes: SCOPES,
    state: JSON.stringify(get(pageState)),
  };
}

export const loginMachine = createMachine(
  {
    id: "LoginMachine",
    initial: "CheckLogin",
    states: {
      LoggedIn: {
        type: "final",
      },
      Popup: {
        initial: "Idle",
        states: {
          Idle: {
            on: {
              SHOW_POPUP: "Active",
            },
          },
          Active: {
            invoke: {
              src: "loginThroughPopup",
              onError: { target: "Idle" },
              onDone: { target: "#LoginMachine.GetUserProfile" },
            },
          },
        },
      },
      CheckLogin: {
        invoke: {
          src: "checkIfAlreadyLoggedIn",
        },
        on: {
          NOT_LOGGED_IN: [
            {
              target: "TriggerRedirect",
              cond: "useRedirect",
            },
            {
              target: "CallbackFailed",
              cond: "useTokenCallback",
            },
            {
              target: "Popup",
            },
          ],
        },
      },
      CallbackFailed: {
        on: {
          TRY_POPUP: "Popup.Active",
        },
      },
      TriggerRedirect: {
        invoke: {
          src: "triggerRedirect",
          onError: { target: "Popup" },
        },
      },
      GetUserProfile: {
        invoke: {
          src: whoami,
          onDone: "LoggedIn",
          onError: "CouldNotGetUserProfile",
        },
      },
      CouldNotGetUserProfile: {},
    },
    on: {
      ACCOUNT_FOUND: {
        target: "GetUserProfile",
      },
    },
    schema: {
      events: {} as
        | { type: "ACCOUNT_FOUND" }
        | { type: "SILENT_LOGIN_FAILED"; error: string }
        | { type: "CALLBACK_FAILED"; error: string }
        | { type: "SHOW_POPUP" }
        | { type: "LOGIN_FAILURE"; error: string }
        | { type: "LOGIN_SUCCESS" }
        | { type: "NOT_LOGGED_IN" }
        | { type: "TRY_POPUP" }
        | { type: "REDIRECT_FRAGMENT_INVALID"; error: string },
      context: {
        flow: "REDIRECT",
      } as {
        flow: AuthenticationFlow;
      },
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    services: {
      checkIfAlreadyLoggedIn: (context) => async (send) => {
        try {
          if (context.flow === "REDIRECT" && window.location.pathname === "/auth/response") {
            const response = await msalInstance.handleRedirectPromise();
            handleResponse(response);
            send("ACCOUNT_FOUND");
          } else if (context.flow === "CALLBACK") {
            await verifyCallbacks();
            send("ACCOUNT_FOUND");
          } else {
            await attemptSilentLogin(getLoginRequest());
            send("ACCOUNT_FOUND");
          }
        } catch (err) {
          console.log("Error verifying login, attempting fallback", err);
          send("NOT_LOGGED_IN");
        }
      },
      loginThroughPopup: () => msalInstance.loginPopup(getLoginRequest()).then(handleResponse),
      triggerRedirect: () =>
        // no need to handle response here, since page is reloaded
        msalInstance.loginRedirect(getLoginRequest()),
    },
    actions: {},
    guards: {
      useRedirect: (context) => context.flow === "REDIRECT",
      useTokenCallback: (context) => context.flow === "CALLBACK",
    },
  },
);

function handleResponse(response: msal.AuthenticationResult | null) {
  if (!response) {
    return;
  }

  msalInstance.setActiveAccount(response.account);

  const state = response.state && JSON.parse(response.state);
  if (isPageState(state)) {
    pushPage(state);
  }
}

async function attemptSilentLogin(loginRequest: LoginRequest) {
  const account = msalInstance.getActiveAccount();
  if (account) {
    await msalInstance.acquireTokenSilent({
      scopes: SCOPES,
      account,
    });
  } else {
    const response = await msalInstance.ssoSilent(loginRequest);
    handleResponse(response);
  }
}

async function verifyCallbacks() {
  const account = getAccount();
  const token = await getAccessToken("CALLBACK");
  if (!account || !token) {
    throw Error("Invalid token and/or account callback");
  }
}

export async function getAccessToken(method: AuthenticationFlow = authFlow): Promise<string> {
  if (method === "CALLBACK") {
    return getTokenUsingCallback();
  }
  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw Error("Kan ikke autentisere brukeren, prøv å laste inn siden på nytt");
  }

  const tokenRequest = {
    scopes: SCOPES,
    account,
  };

  try {
    const response = await msalInstance.acquireTokenSilent(tokenRequest);
    handleResponse(response);
    return response.accessToken;
  } catch (error: unknown) {
    if (error instanceof msal.InteractionRequiredAuthError) {
      if (method === "REDIRECT") {
        // this takes us to redirect, so don't really need to return, but TS is happier
        return msalInstance.acquireTokenRedirect(tokenRequest).then(() => "foo");
      } else {
        const response = await msalInstance.acquireTokenPopup(tokenRequest);
        handleResponse(response);
        return response.accessToken;
      }
    } else {
      throw error;
    }
  }
}

export const getAccount = (): UserAccount => {
  if (getAuthenticationFlow() === "CALLBACK") {
    return getPluginHostAccount();
  }
  const account = msalInstance.getActiveAccount();

  return {
    ...account,
    familyName: account?.idTokenClaims?.family_name?.toString(),
    givenName: account?.idTokenClaims?.given_name?.toString(),
    email: account?.idTokenClaims?.email?.toString(),
    groups: (account?.idTokenClaims?.groups ?? []) as string[],
  };
};

export const getName = () => getAccount()?.name || getAccount()?.username || undefined;

export const getUsername = () => getAccount()?.username;

export const userBelongsToStillsGroup = () => {
  const stillsGroup = getStillsGroupId().split("nrk:").pop();
  return !!stillsGroup && getAccount()?.groups?.includes(stillsGroup);
};
