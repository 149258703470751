<script lang="ts">
  import { orgEditMetadata } from "@nrk/origo";
  import { returnToSearchPage } from "state/page";
  import { onMount } from "svelte";
  import Dialog from "../Common/Dialog.svelte";
  import { type Folder, type FolderMetadata } from "services/folders/types";
  import DialogContent from "./DialogContent.svelte";

  export let onConfirm: (metadata: Partial<FolderMetadata>) => void;
  export let folder: Folder;

  let dialogElement: HTMLElement;
  let showDialog = false;
  let folderName = "";

  function onToggle(this: HTMLElement) {
    if (this !== dialogElement) {
      return;
    } else if (this.hidden) {
      returnToSearchPage();
    }
    folderName = folder.name;
    showDialog = !dialogElement.hidden;
  }

  onMount(() => {
    dialogElement.addEventListener("dialog.toggle", onToggle);
    folderName = folder.name;
    return () => {
      dialogElement.removeEventListener("dialog.toggle", onToggle);
    };
  });
</script>

<button
  class="org-button org-button--hidden-large org-button--icon-left"
  data-for="edit-folder-dialog"
>
  {@html orgEditMetadata}
  Endre
</button>
<bb-dialog id="edit-folder-dialog" hidden bind:this={dialogElement}>
  <!-- We hide the dialog with this boolean so we can use the Svelte lifecycle to start and stop machine -->
  {#if showDialog}
    <Dialog
      confirmText={"Oppdater mappe"}
      headerText={"Oppdater mappe"}
      onConfirm={() => onConfirm({ name: folderName })}
    >
      <DialogContent bind:folderName />
    </Dialog>
  {/if}
</bb-dialog>

<style>
  bb-dialog {
    width: 700px;
    max-width: 90vw;
  }
</style>
