<script lang="ts">
  import { nrkPerson } from "@nrk/core-icons";
  import { orgBell, orgChevronOpen, orgSettings, orgUpload } from "@nrk/origo";
  import {
    getAllImagesLink,
    getMyImagesLink,
    isAllImagesPage,
    isMyImagesPage,
    pageState,
    resetPage,
  } from "state/page";
  import { cancelUpload, cleanupAndResetUploadState, setUploadsIdle, uploads } from "state/upload";
  import HelpMenu from "./HelpMenu.svelte";
  import Header from "./Header.svelte";
  import UploadButton from "./Upload/UploadButton.svelte";
  import { getAccount } from "auth/auth";
  import { first, last } from "lodash-es";

  export let hideNavigation = false;

  $: page = $pageState;
  $: uploadsInProgress = $uploads.state !== "IDLE";

  const account = getAccount();

  const unreadNotifications = 0;

  async function onClickTitle(event: MouseEvent) {
    if (uploadsInProgress) {
      event.preventDefault();
      if (
        $uploads.state !== "ALL_FAILED" &&
        confirm("Hvis du avbryter vil opplastingene slettes")
      ) {
        await cancelUpload();
      } else {
        await cleanupAndResetUploadState();
        setUploadsIdle();
        resetPage();
      }
    }
  }

  $: nameParts = account.name?.split(" ");
  $: nameInitials = nameParts?.map((part) => part.substring(0, 1));
  $: initials = `${first(nameInitials)}${
    nameInitials && nameInitials.length > 1 ? last(nameInitials) : ""
  }`;

  $: allImagesLink = getAllImagesLink($pageState);
  $: myImagesLink = getMyImagesLink($pageState);
</script>

<Header {onClickTitle}>
  {#if !hideNavigation}
    <div class="navigation">
      <div class="navigation__mobile">
        <button class="org-button org-button--active">
          {isMyImagesPage(page) ? "Mine Bilder" : "Alle bilder"}
          {@html orgChevronOpen}
        </button>

        <bb-dropdown hidden>
          <a
            href={allImagesLink}
            aria-disabled={uploadsInProgress}
            class="org-button"
            aria-current={isAllImagesPage(page) ? "page" : undefined}
          >
            Alle bilder
          </a>
          <a
            href={myImagesLink}
            aria-disabled={uploadsInProgress}
            class="org-button"
            aria-current={isMyImagesPage(page) ? "page" : undefined}
          >
            Mine bilder
          </a>
        </bb-dropdown>
      </div>

      <!-- Should be tabs? -->
      <div class="navigation__desktop">
        <a
          href={allImagesLink}
          aria-disabled={uploadsInProgress}
          class="org-button"
          aria-current={isAllImagesPage(page) ? "page" : undefined}
        >
          Alle bilder
        </a>
        <a
          href={myImagesLink}
          aria-disabled={uploadsInProgress}
          class="org-button"
          aria-current={isMyImagesPage(page) ? "page" : undefined}
        >
          Mine bilder
        </a>
      </div>
    </div>
  {:else}
    <div style="flex: 1"></div>
  {/if}

  <div class="right">
    <UploadButton>
      <span class="org-button org-button--suggestive">
        {@html uploadsInProgress ? "" : orgUpload}
        <span>{uploadsInProgress ? "Laster opp…" : "Last opp"}</span>
      </span>
    </UploadButton>
    <div class="user">
      <span class="org-sr">Bruker</span>
      <button class="org-button user-badge">
        {initials}
      </button>

      <bb-dropdown hidden>
        <div class="profile">
          <span class="icon">{@html nrkPerson}</span>
          <span>{account?.name}</span>
        </div>
        <hr class="org-hr" />
        <a class="org-button" aria-disabled={uploadsInProgress} href={myImagesLink}>Mine bilder</a>
        <button class="org-button" disabled>Logg av…</button>
      </bb-dropdown>
      {#if unreadNotifications}
        <sup class="org-badge">{unreadNotifications}</sup>
      {/if}
    </div>
    <HelpMenu />
  </div>
</Header>

<style>
  .navigation {
    flex: 1;
    margin-left: 20px;
  }

  .navigation__mobile {
    display: none;
  }

  .navigation__desktop {
    display: flex;
  }

  .right {
    display: flex;
  }

  .user {
    font-weight: bold;
    padding: var(--org-small) var(--org-medium);
  }

  .user span {
    margin-right: var(--org-small);
  }

  .user-badge {
    width: 2rem;
    height: 2rem;
    border: 1px var(--color-text-primary) solid;
    border-radius: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    font-size: 0.75rem;
  }

  .profile {
    margin: 0.5rem;
  }

  .org-hr {
    opacity: 1;
  }

  @media screen and (max-width: 1024px) {
    .navigation__mobile {
      display: flex;
    }

    .navigation__desktop {
      display: none;
    }
  }
</style>
