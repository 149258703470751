<script lang="ts">
  import { imageTypes, type Image, type ImageType } from "bildebanken-model";
  import { onMount } from "svelte";
  import { mapDigitalSourceTypeToImageType } from "utils/fns";
  import {
    fieldMissingForCurrentImage,
    isRequiredField,
    updateField,
    type EditImageActor,
    type EditImageState,
  } from "./editImageMachine";

  export let service: EditImageActor;
  export let state: EditImageState;
  export let image: Image;

  let touched = false;

  $: fieldMissing =
    isRequiredField(state, "imageType") &&
    fieldMissingForCurrentImage(state, "imageType") &&
    touched;

  $: suggestedImageType =
    image.metadata.digitalSourceType &&
    mapDigitalSourceTypeToImageType(image.metadata.digitalSourceType);

  function onImageTypeChange(this: HTMLSelectElement) {
    touched = true;
    updateField(service, "imageType", this.value as ImageType);
  }

  onMount(() => {
    suggestedImageType && updateField(service, "imageType", suggestedImageType as ImageType);
  });
</script>

<label>
  Bildetype
  <span class={!isRequiredField(state, "imageType") ? "hidden" : ""}>*</span>
  <select
    class="org-input"
    value={image.metadata.imageType || suggestedImageType || ""}
    on:change={onImageTypeChange}
    aria-invalid={fieldMissing}
  >
    <option value=""></option>
    {#each imageTypes as imageType}
      <option value={imageType}> {imageType} </option>
    {/each}
  </select>
  <span class="org-warning">Bildetype påkrevd</span>
</label>

<style>
  .hidden {
    display: none;
  }
</style>
