<script lang="ts">
  import { nrkMore } from "@nrk/core-icons";
  import { isEmbedded } from "config";
  import { formatISO } from "date-fns";
  import { searchFilter, updateSearchFilter } from "state/page";
  import type { DateFilter } from "state/params";
  import type { DistributiveOmit } from "utils/typescript";

  type DateFilterWithoutLabel = DistributiveOmit<DateFilter, "label">;

  function parseFormDate(date: FormDataEntryValue | null): Date | undefined {
    if (!date?.toString()) {
      return;
    } else {
      return new Date(date.toString());
    }
  }

  // keep this as a component variable so we can display the selected value before the filter is set
  $: field = $searchFilter.date?.field || "mediaCreatedOn";

  function getPillLabel(dateFilter: DateFilterWithoutLabel | undefined): string | undefined {
    if (!dateFilter) {
      return;
    }

    if (dateFilter.type === "Range" && dateFilter.to && dateFilter.from) {
      return `${fieldLabels[dateFilter.field]} fra ${dateFilter.from.toLocaleDateString(
        "nb",
      )} til ${dateFilter.to.toLocaleDateString("nb")}`;
    }

    if (dateFilter.type === "Since") {
      return `${fieldLabels[dateFilter.field]} ${secondsBackOptions[dateFilter.secondsBack]}`;
    }
  }

  function setDateFilter(this: HTMLFormElement, event: Event) {
    if (!(event.target instanceof HTMLElement)) return;
    const targetName = event.target.attributes.getNamedItem("name")?.value;

    const formData = new FormData(this);
    const from = parseFormDate(formData.get("from-date"));
    const to = parseFormDate(formData.get("to-date"));

    let fieldRaw = formData.get("date-field")?.toString();
    if (fieldRaw !== "mediaCreatedOn" && fieldRaw !== "createdOn") {
      // this happens after resets
      return;
    }
    field = fieldRaw;

    let newFilter: DateFilterWithoutLabel | undefined;

    // We base the filter type (Since/Range) on the element last interacted with
    switch (targetName) {
      case "date-field": {
        if ($searchFilter.date) {
          // keep filter type
          newFilter = { ...$searchFilter.date, field };
        }
        break;
      }

      case "seconds-back": {
        const rawValue = formData.get("seconds-back")?.toString();
        if (!rawValue) return;
        const secondsBack = Number.parseInt(rawValue);
        // magic number to signify "Range"
        if (secondsBack === -1) return;
        // Reset filter if nothing chosen
        if (secondsBack === 0) return updateSearchFilter("date");
        newFilter = { type: "Since", field, secondsBack };
        break;
      }

      case "from-date":
      case "to-date": {
        if (to && from) {
          newFilter = { type: "Range", field, from, to };
        }
        break;
      }

      default: {
        // should never happen
        console.error("Invalid form target name in date filter", targetName);
      }
    }

    const label = getPillLabel(newFilter);
    if (newFilter && label) {
      updateSearchFilter("date", { ...newFilter, label });
    }
  }

  const fieldLabels = {
    mediaCreatedOn: "Fotografert",
    createdOn: "Lastet opp",
  };

  const secondsBackOptions: Record<string, string> = {
    3600: "siste timen",
    [4 * 3600]: "siste 4 timer",
    [24 * 3600]: "siste 24 timer",
    [7 * 24 * 3600]: "siste uken",
    [31 * 24 * 3600]: "siste måneden",
  };

  let defaultFieldRadioButton: HTMLInputElement;
  function resetFilter() {
    updateSearchFilter("date");

    // Form-reset blanks out all radio buttons, we want to set the default value as checked.
    // Needs to be done in next tick.
    setTimeout(() => (defaultFieldRadioButton.checked = true), 0);
  }
</script>

<button type="button">Dato</button>
<bb-expand hidden>
  <form on:change={setDateFilter}>
    <div class="date-type">
      <span>{fieldLabels[field]}</span>
      <button class="org-button" type="button" aria-label="Endre type dato">
        {@html nrkMore}
      </button>
      <bb-dropdown class="date-field-dropdown" hidden>
        <fieldset class="org-fieldset">
          <legend>Hendelse</legend>
          <label>
            <input
              type="radio"
              value="mediaCreatedOn"
              name="date-field"
              class="org-input"
              checked={field === "mediaCreatedOn"}
              bind:this={defaultFieldRadioButton}
            />
            Fotografert
          </label>
          <label>
            <input
              type="radio"
              value="createdOn"
              name="date-field"
              class="org-input"
              checked={field === "createdOn"}
            />
            Lastet opp
          </label>
        </fieldset>
      </bb-dropdown>
    </div>
    <label>
      Når
      <select name="seconds-back" class="org-input">
        <option value="0" selected={Boolean($searchFilter.date)}>Velg</option>
        {#each Object.entries(secondsBackOptions) as [value, label]}
          <option
            {value}
            selected={$searchFilter.date?.type === "Since" &&
              value === "" + $searchFilter.date.secondsBack}
          >
            {label}
          </option>
        {/each}
        {#if $searchFilter.date?.type === "Range"}
          <option value="-1" selected>Intervall</option>
        {/if}
      </select>
    </label>
    <label>
      Fra
      <input
        class="org-input"
        type="date"
        name="from-date"
        value={$searchFilter.date?.type === "Range" && $searchFilter.date?.from
          ? formatISO($searchFilter.date.from, { representation: "date" })
          : ""}
      />
    </label>
    <label>
      Til
      <input
        class="org-input"
        type="date"
        name="to-date"
        value={$searchFilter.date?.type === "Range" && $searchFilter.date?.to
          ? formatISO($searchFilter.date.to, { representation: "date" })
          : ""}
      />
    </label>

    <button type="reset" on:click={resetFilter} class="org-button org-button--secondary">
      Nullstill dato
    </button>
  </form>
</bb-expand>

<style>
  .date-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .date-type label {
    display: block;
    margin: var(--org-xsmall) 0;
  }
  form {
    margin-bottom: var(--org-medium);
    display: flex;
    flex-direction: column;
    gap: var(--org-small);
  }

  /* Invert color scheme for date picker, to show icon in Chrome. Probably a bug */
  input[type="date"] {
    color-scheme: dark;
  }
  :global(.bb-theme-dark) input[type="date"] {
    color-scheme: light;
  }

  button[type="reset"] {
    margin-top: var(--org-medium);
  }

  bb-dropdown {
    background-color: var(--org-color-gray-600);
    padding: var(--org-xsmall) var(--org-small);
  }
  .date-field-dropdown {
    margin: var(--org-xsmall) 0;
  }

  .date-field-dropdown label {
    margin: var(--org-xsmall) 0;
  }
</style>
