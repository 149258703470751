<script lang="ts">
  import { useSelector } from "@xstate/svelte";
  import { uploads } from "state/upload";
  import type { EditImageActor, EditImageState } from "./editImageMachine";
  import { requiredFieldsMissing } from "./editImageMachine";
  import { closePluginCallback } from "../../services/pluginMessages";

  export let service: EditImageActor;
  export let state: EditImageState;
  export let editOnlyMode: boolean = false;
  export let selectImageOnSave: boolean;
  export let onCancel: () => void;

  export let saveMetadata: () => void;

  const missingFields = useSelector(service, requiredFieldsMissing);
</script>

<div class="org-bar org-bar--secondary">
  <div>
    {#if state.context.images.length > 1}
      <button
        class="org-button"
        type="button"
        aria-pressed={state.matches("Editing.All")}
        on:click|preventDefault={() => service.send({ type: "SHOW_ALL" })}
      >
        Alle
      </button>
      <button
        class="org-button"
        type="button"
        aria-pressed={state.matches("Editing.Single")}
        on:click|preventDefault={() => service.send({ type: "SHOW_SINGLE" })}
      >
        Enkeltvis
      </button>
    {/if}
  </div>
  <hr />
  <div>
    {#if $missingFields}
      <span class="missing-fields">Obligatoriske felter må fylles ut</span>
    {/if}
    {#if state.context.error}
      <span class="org-warning">{state.context.error.message || "Noe gikk galt"}</span>
    {/if}
    <button
      class="org-button"
      type="button"
      on:click={editOnlyMode ? closePluginCallback : onCancel}>Avbryt</button
    >
    <button
      aria-busy={state.matches("Saving")}
      class="org-button org-button--primary"
      type="button"
      disabled={$uploads.state === "IN_PROGRESS" || $missingFields || state.matches("Error")}
      on:click={saveMetadata}
    >
      {(!selectImageOnSave && !editOnlyMode) || editOnlyMode ? "Lagre" : "Bruk bilde"}
    </button>
  </div>
</div>

<style>
  .missing-fields {
    margin-right: var(--org-medium);
    font-style: italic;
  }

  .org-bar {
    background: var(--color-surface-secondary);
    color: var(--color-text-primary);
  }
</style>
