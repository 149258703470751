import type { AxiosProgressEvent } from "axios";
import { omit } from "lodash-es";
import pLimit from "p-limit";
import { derived, get, writable, type Readable } from "svelte/store";
import { v4 as uuidv4 } from "uuid";

import { createUploadPlaceholder, deleteImage, uploadToPlaceholder } from "services/api";
import { diffingDerivedStore, getErrorMessage } from "utils/fns";
import { linkIptcFilenameAndId, storeIptc } from "../utils/exif-iptc";
import { updateSortParams } from "./page";
import { linkThumbnailFilenameAndId, storeUploadThumbnail } from "./thumbnails";

type UploadInit = {
  state: "INITIAL";
  file: File;
  progress: 0;
};

type FailedUpload = {
  state: "FAILED";
  file: File;
  id?: string;
  uploadingUrl?: string;
  progress: number;
  error: string;
  jobId?: string;
};

type ReadyForUpload = {
  state: "READY";
  file: File;
  id: string;
  uploadingUrl: string;
  progress: number;
  jobId?: string;
};

type InProgressUpload = {
  state: "IN_PROGRESS";
  file: File;
  id: string;
  progress: number;
  jobId?: string;
};

type SuccessfulUpload = {
  state: "SUCCESS";
  file: File;
  progress: 1;
  id: string;
  jobId?: string;
};

type FileUpload = UploadInit | ReadyForUpload | InProgressUpload | FailedUpload | SuccessfulUpload;

// exported for tests
export type UploadsState =
  | {
      state: "IDLE";
    }
  | ((
      | {
          state: "PREPARING";
          abortController: AbortController;
        }
      | {
          state: "IN_PROGRESS";
          abortController: AbortController;
        }
      | {
          state: "ALL_UPLOADED" | "SOME_FAILED" | "ALL_FAILED" | "CANCELLING" | "CLEANING_UP";
        }
    ) & {
      /** Map of filenames to upload status */
      files: Record<string, FileUpload>;

      /** Total upload progress */
      progress: number;
    });

function isReadyForUpload(file: FileUpload): file is ReadyForUpload {
  return file.state === "READY";
}

function isFailedUpload(file: FileUpload): file is FailedUpload {
  return file.state === "FAILED";
}

function isSuccessfulUpload(file: FileUpload): file is SuccessfulUpload {
  return file.state === "SUCCESS";
}

function hasPlaceholder<T extends FileUpload>(file: T): file is T & { id: string } {
  return "id" in file;
}

const _uploads = writable<UploadsState>({ state: "IDLE" });

/** Readonly version of uploads state */
export const uploads = derived(_uploads, (value) => value);

const UPLOAD_LIMIT = 3;
const limit = pLimit(UPLOAD_LIMIT);

function onBeforeUnload(event: BeforeUnloadEvent) {
  return (event.returnValue = "Vil du levne siden? Pågående opplastinger vil avbrytes.");
}

/**
 * Uploads files to Mimir.
 *
 * The status of the upload is updated in the singleton store `uploads`.
 *
 * @param files {File[]} Files to upload.
 */
export async function uploadFiles(files: File[]): Promise<void> {
  if (files.length === 0) return;

  if (get(uploads).state !== "IDLE") {
    throw Error("Uploads already in progress");
  }

  window.addEventListener("beforeunload", onBeforeUnload);

  const abortController = new AbortController();
  const signal = abortController.signal;
  signal.addEventListener("abort", limit.clearQueue);

  initUploadsState(files, abortController);

  try {
    await Promise.all([
      ...files.map(storeUploadThumbnail),
      ...files.map(storeIptc),
      createPlaceholders(files, signal),
    ]);

    await performUploads();

    summarizeUploads();

    // This puts the newly uploaded file(s) at the top of the result list
    updateSortParams({ sortBy: "createdOn", sortOrder: "desc" });
  } catch (err: unknown) {
    // this should never happen, but here just in case, to show the user something at least
    _uploads.update((value) => ({ ...value, type: "ALL_FAILED", error: getErrorMessage(err) }));
  } finally {
    window.removeEventListener("beforeunload", onBeforeUnload);
  }
}

function initUploadsState(files: File[], abortController: AbortController) {
  _uploads.set({
    state: "PREPARING",
    files: Object.assign(
      {},
      ...files.map((file) => ({
        [file.name]: {
          state: "INITIAL",
          file,
          progress: 0,
        },
      })),
    ),
    progress: 0,
    abortController,
  });
}

async function createPlaceholders(files: File[], signal: AbortSignal) {
  const fileUploads: Record<string, FileUpload> = {};
  const jobId = uuidv4();

  await Promise.all(
    files.map((file) =>
      limit(() =>
        createUploadPlaceholder(file, signal, jobId)
          .then(({ id, uploadingUrl }) => {
            fileUploads[file.name] = {
              state: "READY",
              file,
              id,
              uploadingUrl,
              progress: 0,
              jobId,
            };
            linkThumbnailFilenameAndId(file.name, id);
            linkIptcFilenameAndId(file.name, id);
          })
          .catch((error) => {
            fileUploads[file.name] = { state: "FAILED", file, error: error.message, progress: 0 };
          })
          .finally(() => {
            _uploads.update((state) => ({
              ...state,
              files: {
                ...(state.state !== "IDLE" ? state.files : {}),
                ...fileUploads,
              },
            }));
          }),
      ),
    ),
  );
}

async function performUploads() {
  const state = get(_uploads);
  if (state.state !== "PREPARING") return;

  _uploads.set({
    ...state,
    state: "IN_PROGRESS",
    progress: 0,
  });

  const tasks = Object.values(state.files)
    .filter(isReadyForUpload)
    .map((file) =>
      // we only want to limit the number of parallel uploads (which requires a lot of bandwidth)
      limit(() => startUpload(file, state.abortController.signal))
        // the rest is done without limiting
        .then(() => setUploadDone(file.file.name))
        .catch((error: unknown) => {
          setUploadFailed(file.file.name, error);
          return null;
        }),
    );

  await Promise.all(tasks);
}

function startUpload(file: FileUpload, signal: AbortSignal) {
  if (file.state !== "READY") return Promise.resolve();

  _uploads.update((state) => {
    if (state.state === "IDLE") {
      return state;
    }

    return {
      ...state,
      files: {
        ...state.files,
        [file.file.name]: {
          ...file,
          progress: 0,
          state: "IN_PROGRESS",
        },
      },
    };
  });

  return uploadToPlaceholder(file, signal, (progressEvent) =>
    setUploadProgress(file.file.name, progressEvent),
  );
}

// Assumption: All files have similar size. TODO: use better calculation
function calculateTotalProgress(upload: UploadsState) {
  if (upload.state === "IDLE") return 0;

  return (
    Object.values(upload.files).reduce((sum, file) => sum + file.progress, 0) /
    Object.keys(upload.files).length
  );
}

function setUploadProgress(filename: string, progress: AxiosProgressEvent) {
  _uploads.update((upload) => {
    if (upload.state !== "IN_PROGRESS") {
      return upload;
    }

    // TODO: not immutable
    upload.files[filename].progress = progress.progress || 0;

    return {
      ...upload,
      progress: calculateTotalProgress(upload),
    };
  });
}

function setUploadFailed(filename: string, error: unknown): void {
  _uploads.update((upload) => {
    if (upload.state !== "IN_PROGRESS") {
      return upload;
    }

    const errorMessage = getErrorMessage(error);
    const updatedFile: FileUpload = {
      ...upload.files[filename],
      state: "FAILED",
      error: errorMessage,
    };

    return {
      ...upload,
      files: {
        ...upload.files,
        [filename]: updatedFile,
      },
    };
  });
}

function setUploadDone(filename: string): void {
  _uploads.update((upload) => {
    if (upload.state !== "IN_PROGRESS") {
      return upload;
    }
    const theFile = upload.files[filename];

    if (theFile.state !== "IN_PROGRESS") return upload;

    const updatedFile: FileUpload = {
      ...theFile,
      progress: 1,
      state: "SUCCESS",
    };

    return {
      ...upload,
      files: {
        ...upload.files,
        [filename]: updatedFile,
      },
    };
  });
}

function summarizeUploads(): void {
  _uploads.update((upload) => {
    if (upload.state !== "IN_PROGRESS") {
      return upload;
    }

    if (Object.values(upload.files).every(isSuccessfulUpload)) {
      return { state: "ALL_UPLOADED", files: upload.files, progress: 1 };
    }

    const progress = calculateTotalProgress(upload);

    if (
      Object.values(upload.files).filter(isFailedUpload).length < Object.values(upload.files).length
    ) {
      return { state: "SOME_FAILED", files: upload.files, progress };
    }

    return { state: "ALL_FAILED", files: upload.files, progress };
  });
}

export function getUploadItemIds(upload: UploadsState): string[] {
  if (upload.state === "PREPARING" || upload.state === "IDLE") {
    return [];
  }

  return Object.values(upload.files)
    .map((file) => ("id" in file ? file.id : undefined))
    .filter(Boolean) as string[];
}

export function getPlaceholderProgress(upload: UploadsState): [number, number] {
  if (upload.state !== "PREPARING") return [0, 0];

  return [
    Object.values(upload.files).filter(hasPlaceholder).length,
    Object.values(upload.files).length,
  ];
}

/** Returns uploads that failed when creating placeholders */
export function getPlaceholderCreationFailures(upload: UploadsState): FailedUpload[] {
  if (upload.state === "IDLE") {
    return [];
  }

  return Object.values(upload.files)
    .filter(isFailedUpload)
    .filter((file) => !file.id);
}

/** Get all failed uploads */
export function getFailedUploads(upload: UploadsState): FailedUpload[] {
  if (upload.state === "IDLE") {
    return [];
  }

  return Object.values(upload.files).filter(isFailedUpload);
}

export function getSuccessfulUploads(upload: UploadsState): SuccessfulUpload[] {
  if (upload.state === "IDLE") {
    return [];
  }

  return Object.values(upload.files).filter(isSuccessfulUpload);
}

/**
 * Get store describing file upload status
 *
 * File is omitted from result to make diffing more efficient
 *
 * @param filename Original file name
 */
export function getFileUploadStatus(
  filename: string,
): Readable<Omit<FileUpload, "file"> | undefined> {
  return diffingDerivedStore(uploads, (value) =>
    value.state === "IDLE" ? undefined : omit(value.files[filename], "file"),
  );
}

export async function cancelUpload() {
  const upload = get(_uploads);

  if (upload.state === "IDLE") {
    return;
  }

  if (upload.state === "PREPARING" || upload.state === "IN_PROGRESS") {
    upload.abortController?.abort();
  }

  _uploads.set({ state: "CANCELLING", progress: upload.progress, files: upload.files });

  await Promise.all(
    Object.values(upload.files)
      .filter(hasPlaceholder)
      .map((file) => limit(() => deleteImage(file.id).catch(console.error))),
  );

  _uploads.set({ state: "IDLE" });
}

export async function cleanupAndResetUploadState() {
  const upload = get(_uploads);

  if (upload.state !== "IDLE") {
    const failedFiles = Object.values(upload.files).filter(isFailedUpload);
    const filesToDelete = Object.values(failedFiles).filter(hasPlaceholder);
    if (filesToDelete.length > 0) {
      _uploads.set({ state: "CLEANING_UP", files: upload.files, progress: upload.progress });
      await Promise.all(
        filesToDelete.map((file) => limit(() => deleteImage(file.id).catch(console.error))),
      );
    }

    _uploads.set({ state: "IDLE" });
  }
}

// for tests
export function setUploadsIdle(): void {
  _uploads.set({ state: "IDLE" });
}

export function isUploadInProgress(upload: UploadsState) {
  return upload.state !== "IDLE";
}
