<script lang="ts">
  import type { Image } from "bildebanken-model";
  import type { EditImageActor } from "./editImageMachine";

  export let service: EditImageActor;
  export let image: Image;

  let value = image.metadata.mediaCreatedOn
    ? new Date(image.metadata.mediaCreatedOn).toISOString().substring(0, 19)
    : "";

  function updateDate(this: HTMLInputElement) {
    service.send({
      type: "EDIT_METADATA",
      value: new Date(this.value).toISOString(),
      field: "mediaCreatedOn",
    });
  }

  // TODO: Add timezone support using Intl.supportedValuesOf('timeZone')
</script>

<label>
  Dato fotografert
  <input type="datetime-local" class="org-input" bind:value on:blur={updateDate} />
</label>
