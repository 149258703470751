import { PAGE_SIZE } from "state/params";

/**
 * Svelte action for infinite scroll
 *
 * @param node Closest parent to list of elements that are being populated
 * @param getMore Function for adding new elements at bottom of list
 * */
export function infiniteScroll(node: HTMLElement, getMore: () => Promise<void>) {
  let lastChild: Element | undefined;

  function update() {
    const newChild = node.children.item(node.children.length - Math.min(20, PAGE_SIZE));
    if (newChild && newChild !== lastChild) {
      if (lastChild) {
        observer.unobserve(lastChild);
      }
      observer.observe(newChild);
      lastChild = newChild;
    }
  }

  const observer = new IntersectionObserver((entries) => {
    const intersecting = entries[0].isIntersecting;
    if (intersecting) {
      getMore();
      if (lastChild) {
        observer.unobserve(lastChild);
      }
    }
  });

  update();

  return {
    update,
    destroy() {
      if (lastChild) {
        observer.unobserve(lastChild);
      }
      observer.disconnect();
    },
  };
}
