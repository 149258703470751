import { getNtbProxyUrl } from "../../config";
import type { Ntb } from "bildebanken-model";
import type { SearchParams } from "state/params";
import { PAGE_SIZE } from "state/params";
import { mapToSearchHits, ntbImageToSearchHit, type NtbSearchHit } from "../searchHit";
import { apiFetch } from "../fetch-wrapper";
import { extractNtbIdFromUrl } from "../api";

export type MappedSuccessfulNtbSearchResult = {
  total: number;
  hits: NtbSearchHit[];
  from: number;
};

/**
 * Search for Ntb images
 *
 * @param {SearchParams} params Search params
 * @param {AbortSignal} [signal] To abort the request
 * @returns `void` if the request is cancelled via the optional signal
 */
export async function searchNtb(
  params: SearchParams,
  signal?: AbortSignal,
): Promise<MappedSuccessfulNtbSearchResult> {
  if (
    params.owner ||
    params.filter.sourceSystems?.length ||
    params.filter.rightsMarker === "Free"
  ) {
    return { total: 0, hits: [], from: 0 };
  }
  const ntbUrl = new URL("search", getNtbProxyUrl());
  const queryIsNtbUrl = extractNtbIdFromUrl(params.query);

  ntbUrl.searchParams.append("insideSubscription", "true");
  if (params.query) {
    if (queryIsNtbUrl) {
      params = { ...params, query: queryIsNtbUrl };
    }
    ntbUrl.searchParams.append("query", params.query);
  }

  const sortLabels = {
    mediaCreatedOn: "created",
    createdOn: "uploaded",
  };

  function mapNtbSorting(params: SearchParams): string {
    return `${sortLabels[params.sort.sortBy]}:${params.sort.sortOrder}`;
  }

  if (params.from) {
    ntbUrl.searchParams.append("offset", params.from.toString());
  }

  if (params.filter.date) {
    switch (params.filter.date.type) {
      case "Since": {
        const date = new Date();
        date.setSeconds(date.getSeconds() - params.filter.date.secondsBack);
        ntbUrl.searchParams.append("createdMin", convertToNtbTimeFormat(date));
        break;
      }
      case "Range":
        if (params.filter.date.from) {
          ntbUrl.searchParams.append("createdMin", convertToNtbTimeFormat(params.filter.date.from));
        }
        if (params.filter.date.to) {
          ntbUrl.searchParams.append("createdMax", convertToNtbTimeFormat(params.filter.date.to));
        }
        break;
    }
  }

  function convertToNtbTimeFormat(date: Date): string {
    // NTB expects dates in ISO 8601 format with a timezone offset and does nut support Z for UTC
    return date.toISOString().replace("Z", "+0000");
  }
  ntbUrl.searchParams.append("sort", mapNtbSorting(params));
  ntbUrl.searchParams.append("limit", PAGE_SIZE.toString());

  const response = await apiFetch(ntbUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    signal,
  });

  if (!response.ok) {
    throw Error("Kunne ikke søke i Ntb");
  }

  const searchResponse = (await response.json()) as Ntb.SuccessfulSearchResult;

  return {
    total: searchResponse._page.total,
    hits: mapToSearchHits(searchResponse._data, ntbImageToSearchHit),
    from: params.from,
  };
}
export async function copyNtbImageToMimir(
  ntbId: string,
): Promise<{ id: string; height: number; width: number }> {
  const response = await apiFetch(`${getNtbProxyUrl()}${ntbId}/copytomimir`, {
    method: "POST",
  });
  if (!(response.status === 201 || response.status === 303)) {
    //201 303 er ok states
    console.log("some error state: ", response.status);
  }

  return await response.json();
}
