export function preload(node: Node, preloader: () => Promise<void>) {
  const debounce = 100;
  let timeout: number;

  const mouseOver = () => {
    timeout = window.setTimeout(preloader, debounce);
  };
  const mouseLeave = () => clearTimeout(timeout);

  const events: { event: string; callback: () => void }[] = [
    { event: "mouseenter", callback: mouseOver },
    { event: "mouseleave", callback: mouseLeave },
    { event: "focus", callback: mouseOver },
    { event: "blur", callback: mouseLeave },
  ];
  events.forEach(({ event, callback }) => {
    node.addEventListener(event, callback);
  });

  return {
    destroy() {
      events.forEach(({ event, callback }) => {
        node.removeEventListener(event, callback);
      });
    },
  };
}
