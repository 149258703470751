import { showUpload } from "state/page";
import { cleanupAndResetUploadState, uploadFiles } from "state/upload";

const collator = new Intl.Collator("nb", { numeric: true, sensitivity: "base" });

const sortFiles = (files: FileList | File[]) => {
  return Array.from(files).sort((a, b) => collator.compare(a.name, b.name));
};

function renameFileName(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

function constructUniqueFileName(fileName: string, counter: number): string | undefined {
  const extensionIndex = fileName.lastIndexOf(".");
  if (extensionIndex > 0) {
    const name = fileName.slice(0, extensionIndex);
    const extension = fileName.slice(extensionIndex + 1);
    return name + " (" + counter + ")." + extension;
  }
}

function makeAllFileNamesUnique(files: FileList | File[]) {
  const fileNames: string[] = [];
  let counter = 0;
  return Array.from(files).map((file) => {
    if (fileNames.includes(file.name)) {
      counter += 1;
      return renameFileName(file, constructUniqueFileName(file.name, counter));
    }
    fileNames.push(file.name);
    return file;
  });
}

export async function upload(files: FileList | File[]) {
  if (files && files.length > 0) {
    await cleanupAndResetUploadState();
    files = makeAllFileNamesUnique(files);
    const sortedFiles = sortFiles(files);
    uploadFiles(sortedFiles);
    showUpload();
  }
}
