<script lang="ts">
  import type { Image } from "bildebanken-model";
  import { infiniteScroll } from "components/SearchPage/infiniteScroll";
  import { preload } from "components/SearchPage/preload";
  import { getBildebankenHost } from "config";
  import { type MimirSearchHit } from "services/searchHit";
  import { getDefaultSearchParams } from "state/params";
  import { getSearchHitsFromQuery } from "state/queries";
  import { createMimirSearchQuery, createPreloader } from "state/queries/mimir";
  import { fade } from "svelte/transition";

  export let jobId;
  export let image: Image;

  let containerElement: HTMLDivElement;

  $: imageId = image.id;

  async function getMore() {
    await $infiniteQuery.fetchNextPage();
  }

  $: infiniteQuery = createMimirSearchQuery({ ...getDefaultSearchParams(), filter: { jobId } });
  $: relatedImages = getSearchHitsFromQuery(infiniteQuery);

  function isCurrentImage(image: MimirSearchHit, detailImageId: string): boolean {
    return detailImageId === image.id;
  }

  function horizontalScroll(event: WheelEvent) {
    event.preventDefault();
    containerElement.scrollLeft += event.deltaY;
  }

  function createJobIdSearchLink() {
    return new URL(`/images?jobId=${jobId}`, getBildebankenHost()).toString();
  }

  const preloader = (image: MimirSearchHit) => {
    return createPreloader(image);
  };
</script>

{#if $relatedImages && $relatedImages.length > 1}
  <div in:fade={{ delay: 100 }} class="container">
    <h3 title="Disse bildene ble lastet opp i samme opplastning.">
      <a
        href={createJobIdSearchLink()}
        aria-label="Åpne relaterte bilder i søk"
        data-testid="relatedImagesSearchPageLink"
      >
        Relaterte bilder
      </a>
    </h3>
    <div
      bind:this={containerElement}
      class="image-container"
      use:infiniteScroll={$relatedImages && getMore}
      on:wheel|nonpassive={horizontalScroll}
      data-testid="relatedImagesContainer"
    >
      {#each $relatedImages as image}
        <div
          class:current-image={isCurrentImage(image, imageId)}
          use:preload={preloader(image)}
          class="image"
        >
          <a href={image.detailPageUrl} aria-label="Åpne bilde i detalj visning">
            <img src={image.thumbnailUrl} alt="" />
          </a>
        </div>
      {/each}
    </div>
  </div>
{/if}

<style>
  .container {
    max-width: 100%;
    position: relative;
    bottom: 0px;
  }

  .image-container {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;

    gap: var(--org-xsmall);
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  img {
    width: 80px;
    height: 60px;
    object-fit: cover;
  }

  .image {
    border: 2px transparent solid;
  }

  .current-image {
    border-radius: var(--bb-standard-border-radius);
    border: 2px var(--org-color-primary) solid;
  }
</style>
