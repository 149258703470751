<svelte:options immutable />

<script lang="ts">
  import { orgLocked } from "@nrk/origo";
  import ImagePreview from "components/Common/ImagePreview.svelte";
  import RightsIndicator from "components/Common/RightsIndicator.svelte";
  import Timestamp from "components/Common/Timestamp.svelte";
  import { isEmbedded } from "config";
  import { type SearchHit } from "services/searchHit";
  import { readable, type Readable } from "svelte/store";
  import { hasImageCreatedDate } from "utils/fns";
  import { preload } from "../preload";
  import type { SearchHitHandler } from "../searchHitHandler";
  import SearchHitSrSelectionCheckbox from "../SearchHitSrSelectionCheckbox.svelte";
  import { useFeatureToggle } from "services/configCatService";
  import { createEventDispatcher } from "svelte";

  export let searchHit: SearchHit;
  export let handler: SearchHitHandler;
  export let preloader: () => Promise<void>;
  export let selected: Readable<boolean> = readable(false);
  export let sortBy: "mediaCreatedOn" | "createdOn";
  export let selectable = false;


  let focus = false;

  const timestamp = sortBy === "mediaCreatedOn" && searchHit.state === "complete" && searchHit.mediaCreatedOn ? searchHit.mediaCreatedOn : searchHit.itemCreatedOn; 
  // Temporary work-around to display correct image capture time. See issue DS-3234.
  // Only compensate images with image creation date.
  // Update: Also adjust timezone for NTB pictures, as they are without timezone (local time)
  $: imageCreatedDateAvailable = hasImageCreatedDate(searchHit);
  $: ntbPicutre = searchHit.type === "Ntb";
  $: timeZone = imageCreatedDateAvailable && !ntbPicutre ? "UTC" : undefined;

  $: rightsDescription =
    searchHit.metadata.usageTerms ||
    searchHit.metadata.credit ||
    searchHit.metadata.copyrightOwners?.filter((owner) => owner !== "NRK").join(", ");

  const dispatch = createEventDispatcher<{
    drag_start: { event: DragEvent; searchHit: SearchHit };
  }>();
</script>

<!-- Don't want to use aria-selected or role here, it's the checkbox that's actually selected -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="tile"
  class:focus
  draggable="true"
  on:dragstart={(event) => dispatch("drag_start", { event, searchHit })}
  use:preload={preloader}
  on:click={handler.onClickSearchHit}
  on:dblclick={handler.onDblClickSearchHit}
  on:keydown
  on:mousedown={(event) => {
    if (event.shiftKey) {
      document.getSelection()?.removeAllRanges();
    }
  }}
  id={searchHit.id}
  data-selected={$selected}
  data-testid="search-hit"
>
  <div class="tile-image" style="position: relative;">
    {#await useFeatureToggle("showRightsInPluginTiles") then featureEnabled}
      {#if isEmbedded() && featureEnabled}
        {#if searchHit.metadata.rightsMarker !== "Free"}
          <div class="usage-terms">
            <div class="rights-indicator">
              <RightsIndicator rightsMarker={searchHit.metadata.rightsMarker} />
            </div>
            {#if rightsDescription}
              <div
                class="rights-description"
                class:rights-description-note={searchHit.metadata.usageTerms}
              >
                {rightsDescription || ""}
              </div>
            {/if}
          </div>
        {/if}
        {#if searchHit.visibleTo == "owner"}
          <div class="visibility">
            {@html orgLocked}
          </div>
        {/if}
      {/if}
    {/await}
    <ImagePreview image={searchHit} mode="tile" />
  </div>
  {#if !isEmbedded()}
    <div class="description">
      <div class="checkbox-and-detail-link">
        {#if selectable}
          <SearchHitSrSelectionCheckbox
            {handler}
            {searchHit}
            selected={$selected}
            bind:focus
            {preloader}
          />
        {/if}
        {#if searchHit.detailPageUrl}
          <a
            href={searchHit.detailPageUrl}
            class="title overflow-ellipsis"
            class:org-muted={!searchHit.metadata.title}
          >
            {searchHit.metadata.title || "Ingen tittel"}
          </a>
        {:else}
          <span class="title overflow-ellipsis" class:org-muted={!searchHit.metadata.title}
            >{searchHit.metadata.title || "Ingen tittel"}</span
          ><br />
        {/if}
      </div>
      <div class="details-wrapper">
        <div class="timestamp overflow-ellipsis">
          <Timestamp
            sortBy={sortBy}
            date={timestamp}
            {timeZone}
          />
        </div>
        <div>
          {#if searchHit.visibleTo === "owner"}
            <span class="icon" title="Kun synlig for eier">{@html orgLocked}</span>
          {:else if searchHit.visibleTo === "selected-groups"}
            <span class="icon" title="Kun synlig for noen grupper">{@html orgLocked}</span>
          {/if}
          {#if searchHit.metadata.rightsMarker !== "Free"}
            <RightsIndicator rightsMarker={searchHit.metadata.rightsMarker} />
          {/if}
        </div>
      </div>
    </div>
  {/if}
</div>

<style>
  .tile {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: var(--bb-standard-border-radius);
    border: 2px transparent solid;
    transition: border 0.25s ease-out;
    max-height: 100%;
    height: calc(250px * var(--scale));
    color: var(--color-text-primary);
    position: relative;
    /* Ideally we'd have a scroll-margin here, like we have for the table view,
       but doesn't seem to work with grid layout? */
    font-weight: bold;
  }

  .usage-terms {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: var(--org-xsmall);
    top: 20%;
    background: var(--color-surface-primary);
    border-radius: var(--org-xsmall);
    overflow: hidden;
    font-size: 12px;
    visibility: hidden;
  }

  .rights-indicator {
    padding-inline: var(--org-xsmall);
    margin-top: -2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rights-description {
    display: flex;
    font-weight: 500;
    padding-inline: var(--org-xsmall);
    white-space: nowrap;
    max-width: 220px;
    text-overflow: ellipsis;
  }

  .rights-description-note {
    background: var(--nrk-color-red-500);
    color: white;
  }

  .tile:hover .usage-terms {
    visibility: visible;
  }

  .visibility {
    position: absolute;
    bottom: 10%;
    left: var(--org-xsmall);
    background: var(--color-surface-secondary);
    border-radius: var(--org-xsmall);
    font-size: 10px;
    padding: 4px;
    display: flex;
    justify-content: center;
  }

  .tile:hover {
    border-color: rgba(23, 105, 255, 0.35);
    background-color: var(--bb-selected-hover-bg-color);
  }

  .tile-image {
    height: calc(250px * var(--scale));
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    width: 100%;
  }

  .description {
    background: var(--bb-tile-bg-color);
    display: flex;
    flex-direction: column;
    flex: 0 0 70px;
    max-height: calc(150px * var(--show-description));
    padding: clamp(
      calc(10px * var(--show-description)),
      calc(var(--scale) * var(--show-description) * 15px),
      calc(20px * var(--show-description))
    );
  }

  .checkbox-and-detail-link {
    display: flex;
    align-items: center;
    margin-bottom: var(--org-xsmall);
  }

  [data-selected="true"],
  [data-selected="true"]:hover {
    border-color: var(--org-color-primary);
  }

  .tile.focus,
  [data-selected="true"].tile.focus {
    border-color: var(--org-color-focus);
  }

  [data-selected="true"] .description {
    background: var(--bb-selected-bg-color);
    color: var(--org-white);
  }

  .details-wrapper {
    display: flex;
    flex-direction: row;
    gap: var(--org-xsmall);
    justify-content: space-between;
  }

  .timestamp {
    color: var(--org-color-gray-400);
    margin-left: 2px;
  }

  .icon {
    font-size: 0.75rem;
    color: var(--org-color-gray-400);
  }

  @media screen and (max-width: 1024px) {
    .tile {
      height: 148px;
    }
    .tile-image {
      height: 93px;
    }
  }
</style>
