import type { Image, Mimir } from "bildebanken-model";
import { getApiUrl, getBildebankenHost } from "config";
import { apiFetch } from "services/fetch-wrapper";
import { ingestKaleidoImageToBildebanken } from "services/kaleido";
import { getEnvironmentFromHostname } from "utils/fns";
import type { Folder, FolderImage, FolderMetadata, UpdateMetadataResponse } from "./types";
import { rootFolderIds } from "components/Folders/folders";
import { copyNtbImageToMimir } from "services/ntb";

export const rootFolderId = ["prod", "beta"].includes(
  getEnvironmentFromHostname(getBildebankenHost().hostname),
)
  ? rootFolderIds.prod.productions
  : rootFolderIds.stage.productions;

export async function importKaleidoImage(image: FolderImage): Promise<FolderImage | undefined> {
  try {
    const mimirImageId = await ingestKaleidoImageToBildebanken(image.id);
    return {
      ...image,
      id: mimirImageId,
      type: "Mimir",
    };
  } catch (error) {
    console.error("Failed to import Kaleido image for Folder", error);
  }
}
export async function importNtbImage(image: FolderImage): Promise<FolderImage | undefined> {
  try {
    const mimirImageId = await copyNtbImageToMimir(image.id);
    return {
      ...image,
      id: mimirImageId.id,
      type: "Mimir",
    };
  } catch (error) {
    console.error("Failed to import Ntb image for Folder", error);
  }
}

export async function createFolder(name: string, parent: string): Promise<Folder> {
  const response = await apiFetch(`${getApiUrl()}/folders`, {
    method: "POST",
    body: JSON.stringify({ name, parent }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (!response.ok) {
    throw Error(`Could not create folder '${name}'. ${response.body}`);
  }

  return response.json();
}

export async function getFolder(folderId: string): Promise<Mimir.Folder> {
  const response = await apiFetch(`${getApiUrl()}/folders/${folderId}`, { method: "GET" });

  if (!response.ok) {
    throw Error(`Could not fetch folder '${folderId}'. ${response.body}`);
  }

  return response.json();
}

export async function updateFolder(
  folderId: string,
  metadata: Partial<FolderMetadata>,
): Promise<UpdateMetadataResponse> {
  const response = await apiFetch(`${getApiUrl()}/folders/${folderId}`, {
    method: "PATCH",
    body: JSON.stringify(metadata),
    headers: {
      "content-type": "application/json",
    },
  });

  if (!response.ok) {
    throw Error(`Could not update folder '${metadata.name}'. ${response.body}`);
  }

  return response.json();
}

export async function deleteFolder(folderId: string) {
  const response = await apiFetch(`${getApiUrl()}/folders/${folderId}`, { method: "DELETE" });

  if (!response.ok) {
    throw Error(`Could not delete folder '${folderId}'. ${response.body}`);
  }
}

export async function getFolderContent(
  folderId: string,
): Promise<{ folders: Folder[]; images: FolderImage[] }> {
  const response = await apiFetch(`${getApiUrl()}/folders/${folderId}/content`, { method: "GET" });

  if (!response.ok) {
    if (response.status === 403) {
      throw Error("No access to this page");
    }
    throw Error("Could not get content for folderId " + folderId);
  }

  const body = await response.json();

  if (!response.ok) {
    throw Error(`Could not get folder content. ${response.body}`);
  }

  return {
    folders: body.folders,
    images: mapImagesToFolderImages(body.images),
  };
}

export async function addItemToFolder(folderId: string, itemId: string) {
  const response = await apiFetch(`${getApiUrl()}/folders/${folderId}/content`, {
    method: "PUT",
    body: JSON.stringify({ id: itemId }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (!response.ok) {
    throw Error(`Could not add item to folder '${folderId}'. ${response.body}`);
  }
}

export async function removeItemFromFolder(folderId: string, itemId: string) {
  const url = new URL(`${getApiUrl()}/folders/${folderId}/content`);
  url.searchParams.append("id", itemId);

  const response = await apiFetch(url.toString(), { method: "DELETE" });

  if (!response.ok) {
    throw Error(`Could not delete item '${itemId}' from folder '${folderId}'. ${response.body}`);
  }
}

export async function getFolderAncestry(folderId: string): Promise<Mimir.AncestryResponse> {
  const response = await apiFetch(`${getApiUrl()}/folders/${folderId}/ancestry`, { method: "GET" });

  if (!response.ok) {
    throw Error(`Could not fetch folder ancestry for folder '${folderId}'. ${response.body}`);
  }

  return response.json();
}

export async function createPath(path: string, rootFolderId: string): Promise<Mimir.PathResponse> {
  const body = { path, rootFolderId };

  const response = await apiFetch(`${getApiUrl()}/folders/path`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "content-type": "application/json",
    },
  });

  if (!response.ok) {
    throw Error(`Could not create path '${path}' in root '${rootFolderId}'`);
  }

  return response.json();
}

function mapImagesToFolderImages(images: Image[]): FolderImage[] {
  return images.map((img) => {
    return {
      id: img.id,
      thumbnail: img.thumbnail,
      title: img.title,
      type: "Mimir",
    };
  });
}
